import {
	firestoreOld as db
	//firebaseAppOld as firebase,
} from "../../../../../firebase"
import firebase from "firebase/compat/app"
// import { storage } from "../../../../../firebase";

// const firestoreDate = new Date(
//   firebase.firestore.Timestamp.now().seconds * 1000
// );

const customersRef = db.collection("Customers")
const sitesRef = db.collection("Sites")
const infraRef = db.collection("Infrastructure")

function callUpdateApprovedNodes(params) {
	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
	myHeaders.append("Content-Type", "application/json")

	const raw = JSON.stringify({
		...params
	})

	const requestOptions = {
		method: "PUT",
		headers: myHeaders,
		body: raw,
		redirect: "follow"
	}
	return fetch(
		`${process.env.REACT_APP_CUSTOMER_API_BASE_URL_NEW_VERSION_V2}/infrastructure/update-approved-nodes`,
		requestOptions
	).catch(err => {
		console.log("Error: ", err)
	})
}

export function updateUrls(queryParams) {
	// console.log(queryParams, "params no CRUD");
	if (!queryParams) {
		return Promise.resolve(null)
	}

	if (
		!queryParams.zoneToUpdateUrls ||
		!queryParams.customerId ||
		!queryParams.newImagesUrls ||
		!queryParams.floorPlanId ||
		!queryParams.geoJson
	) {
		return Promise.resolve(null)
	}

	const zoneId = queryParams?.zoneToUpdateUrls[0]?.id
	return Promise.all([
		customersRef.doc(`${queryParams.customerId}`).collection("Zones").doc(zoneId).update({
			imagesUrls: queryParams.newImagesUrls
		}),
		customersRef.doc(`${queryParams.customerId}/FloorPlans/${queryParams.floorPlanId}`).update({
			geoJson: queryParams.geoJson
		})
	])
}

export function getZonesInDB({ floorPlanId, networkId }) {
	if (!floorPlanId || !networkId) {
		return Promise.resolve(null)
	}

	return sitesRef.doc(networkId).collection(`Areas`).where("floorPlanId", "==", floorPlanId).get()
}

export function saveUrls(queryParams) {
	// console.log(queryParams, "Params CRUD");
	if (!queryParams) {
		return Promise.resolve(null)
	}

	if (
		!queryParams.customerId ||
		!queryParams.floorPlanId ||
		!queryParams.geoJson ||
		!queryParams.row ||
		!queryParams.row.geoJson ||
		!queryParams.row.geoJson.properties ||
		!queryParams.row.geoJson.properties.zoneId ||
		!queryParams.result
	) {
		return Promise.reject(new Error("Invalid queryParams")) // Reject with an error for incomplete data
	}

	return Promise.all([
		customersRef.doc(`${queryParams.customerId}/FloorPlans/${queryParams.floorPlanId}`).update({
			geoJson: queryParams.geoJson
		}),
		customersRef
			.doc(`${queryParams.customerId}`)
			.collection("Zones")
			.doc(queryParams.row.geoJson.properties.zoneId)
			.update({
				// arrayUnion - só adiciona as novas da Array
				imagesUrls: firebase.firestore.FieldValue.arrayUnion(...queryParams.result)
			})
	])
}

export function saveGeoJson(queryParams) {
	// console.log(queryParams, "queryParams CRUD");
	if (!queryParams) {
		return Promise.resolve(null)
	}

	if (
		!queryParams.promises ||
		!queryParams.networkId ||
		!queryParams.floorPlanId ||
		!queryParams.geoJson
	) {
		return Promise.reject(new Error("Invalid queryParams")) // Reject with an error for incomplete data
	}

	return Promise.all([
		...queryParams.promises,
		sitesRef.doc(`${queryParams.networkId}/FloorPlans/${queryParams.floorPlanId}`).update({
			geoJson: queryParams.geoJson
		})
	])

	//TODO: old code to be deleted
	//   customersRef
	//     .doc(`${queryParams.customerId}/FloorPlans/${queryParams.floorPlanId}`)
	//     .update({
	//       geoJson: queryParams.geoJson,
	//     }),
	// ]);
}

export function saveLayerTypes(queryParams) {
	if (!queryParams) {
		return Promise.resolve(null)
	}

	if (!queryParams.customerId || !queryParams.layerTypes) {
		return Promise.reject(new Error("Invalid queryParams")) // Reject with an error for incomplete data
	}

	return customersRef.doc(`${queryParams.customerId}`).update({
		layerTypes: queryParams.layerTypes
	})
}

export function deleteLayerTypes(queryParams) {
	if (!queryParams) {
		return Promise.resolve(null)
	}

	if (!queryParams.customerId || !queryParams.layerTypes) {
		return Promise.reject(new Error("Invalid queryParams")) // Reject with an error for incomplete data
	}

	return customersRef.doc(`${queryParams.customerId}`).update({
		layerTypes: queryParams.layerTypes
	})
}

export function updateLayerTypes(queryParams) {
	if (!queryParams) {
		return Promise.resolve(null)
	}

	if (!queryParams.customerId || !queryParams.layerTypes) {
		return Promise.reject(new Error("Invalid queryParams")) // Reject with an error for incomplete data
	}

	return customersRef.doc(`${queryParams.customerId}`).update({
		layerTypes: queryParams.layerTypes
	})
}

export function saveZones({ data, customerId, netId, floorPlanId }) {
	if (!Array.isArray(data)) {
		// throw new Error('The "data" parameter must be an array.')
		return Promise.resolve(null)
	}

	return Promise.all(
		data.map(zone => {
			// console.log(zone, "zone");
			return customersRef.doc(`${customerId}`).collection("Zones").doc().update({
				name: zone.name,
				color: zone.color,
				polygon: zone.coords,
				floorPlanId: floorPlanId,
				netId: netId,
				limit: zone.capacity,
				wirelessAP: []
			})
		})
	)
}

export function deleteZones(queryParams) {
	// console.log(queryParams, "queryParams CRUD");
	if (!queryParams) {
		return Promise.resolve(null)
	}

	if (
		!queryParams.zoneToDelete ||
		!queryParams.zoneToDelete.type ||
		!queryParams.networkId ||
		!queryParams.floorPlanId ||
		!queryParams.geoJson
	) {
		return Promise.reject(new Error("Invalid queryParams")) // Reject with an error for incomplete data
	}

	if (queryParams.zoneToDelete.type === "baseLayer") {
		return Promise.resolve(
			sitesRef.doc(`${queryParams.networkId}/FloorPlans/${queryParams.floorPlanId}`).update({
				geoJson: queryParams.geoJson
			})
		)
	}

	return Promise.all([
		sitesRef
			.doc(`${queryParams.networkId}`)
			.collection("Areas")
			.doc(queryParams.zoneToDelete.geoJson.properties.areaId)
			.delete(),
		sitesRef.doc(`${queryParams.networkId}/FloorPlans/${queryParams.floorPlanId}`).update({
			geoJson: queryParams.geoJson
		})
	])
}

export function saveMarkersFromZones(queryParams) {
	if (!queryParams) {
		return Promise.resolve(null)
	}

	if (
		!queryParams.networkId ||
		!queryParams.marker.markerOfAreaId ||
		!queryParams.marker.coords ||
		!queryParams.marker
	) {
		return Promise.reject(new Error("Invalid queryParams")) // Reject with an error for incomplete data
	}

	return sitesRef
		.doc(`${queryParams.networkId}`)
		.collection("Areas")
		.doc(queryParams.marker.markerOfAreaId)
		.update({
			marker: queryParams.marker.coords
		})
}

export function saveDoorMarkersFromZones(queryParams) {
	if (!queryParams) {
		return Promise.resolve(null)
	}

	if (
		!queryParams.networkId ||
		!queryParams.doorMarker.markerOfAreaId ||
		!queryParams.doorMarker.coords ||
		!queryParams.doorMarker
	) {
		return Promise.reject(new Error("Invalid queryParams")) // Reject with an error for incomplete data
	}

	return sitesRef
		.doc(`${queryParams.networkId}`)
		.collection("Areas")
		.doc(queryParams.doorMarker.markerOfAreaId)
		.update({
			doorMarker: queryParams.doorMarker.coords
		})
}

export function getResourcesMeetings({ customerId, networkId }) {
	if (!customerId || !networkId) {
		return Promise.resolve(null)
	}

	return customersRef
		.doc(customerId)
		.collection(`Resources`)
		.where("networkId", "==", networkId)
		.where("type", "==", "meeting")
		.orderBy("created.date")
		.get()
}

//★━━━━━━━━━★ Points of Interest CRUD ★━━━━━━━━━★\\
//★━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━★\\

export async function createPointOfInterest({
	data,
	siteId,
	user,
	imagesUrls,
	floorPlanId,
	coords,
	markerVisible
}) {
	// console.log(user, "user");
	// console.log("New Point of Interest created", data);
	// console.log(imagesUrls, "imagesUrls CRUD");
	if (!siteId || !data || !user || !imagesUrls || !floorPlanId || !coords || !markerVisible) {
		return Promise.resolve(null)
	}

	const ref = sitesRef.doc(siteId).collection("PointsOfInterest").doc()

	await ref.set({
		name: data.name,
		description: data.description,
		type: data.type,
		imagesUrls: imagesUrls,
		siteId: siteId,
		created: {
			id: user.id,
			email: user.email,
			date: firebase.firestore.FieldValue.serverTimestamp()
		},
		floorPlanId: floorPlanId,
		coords: coords,
		markerVisible: markerVisible
	})
	return ref.id
}

export async function updatePointOfInterest({ data, siteId, user, newImagesUrlsArray }) {
	if (!siteId || !data || !user) {
		return Promise.resolve(null)
	}

	const id = data.id
	const ref = sitesRef.doc(siteId).collection("PointsOfInterest").doc(id)
	// console.log("New Point of Interest updated", data);

	await ref.update({
		name: data.name,
		description: data.description,
		type: data.type,
		imagesUrls: newImagesUrlsArray,
		siteId: siteId,
		updated: {
			id: user.id,
			email: user.email,
			date: firebase.firestore.FieldValue.serverTimestamp()
		},
		markerVisible: data.markerVisible
	})
	return ref.id
}

export function savePointsOfInterest({ data, customerId }) {
	// console.log("Data: ", data);
	if (!customerId || !data) {
		return Promise.resolve(null)
	}

	return Promise.all(
		data.map(resource => {
			if (resource.id !== undefined) {
				return customersRef
					.doc(`${customerId}`)
					.collection("pointsOfInterest")
					.doc(resource.id)
					.update({
						floorPlanId: resource.floorPlanId || "",
						networkId: resource.networkId || "",
						coords: resource.coords || ""
					})
			}
			return null
		})
	)
}

export function saveMarkersFromPOIs(queryParams) {
	if (!queryParams) {
		return Promise.resolve(null)
	}

	return sitesRef
		.doc(queryParams.siteId)
		.collection("PointsOfInterest")
		.doc(queryParams.marker.markerOfPOIsId)
		.update({
			coords: queryParams.marker.coords || "",
			floorPlanId: queryParams.marker.floorPlanId || ""
		})
}

// export function saveMarkerVisibilityFromPOIs(queryParams) {
//   // console.log(queryParams, "Params CRUD saveMarkersFromPOIs");
//   return customersRef
//     .doc(`${queryParams.customerId}`)
//     .collection("pointsOfInterest")
//     .doc(queryParams.poiId)
//     .update({
//       markerVisible: queryParams.markerVisible,
//     });
// }

export function getPointsOfInterest(queryParams) {
	if (!queryParams) {
		return Promise.resolve(null)
	}

	return sitesRef
		.doc(queryParams.siteId)
		.collection(`PointsOfInterest`)
		.orderBy("created.date")
		.get()
}

export function deletePointOfInterest({ id, siteId }) {
	if (!id || !siteId) {
		return Promise.resolve(null)
	}

	return sitesRef.doc(`${siteId}/PointsOfInterest/${id}`).delete()
}

export function getPointOfInterestToUpdate({ id, siteId }) {
	if (!siteId || !id) {
		return Promise.resolve(null)
	}
	// console.log(id, "id on CRUD");

	// return customersRef
	//   .doc(customerId)
	//   .collection(`pointsOfInterest`)
	//   .doc(id)
	//   .get();

	return sitesRef.doc(`${siteId}/PointsOfInterest/${id}`).get()
}

// ━━━━━━━━━━━━━━━━━━━━━━━━ Anchors Position ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ \\

export function getAnchorsCollection(queryParams) {
	if (!queryParams) {
		return Promise.resolve(null)
	}
	return sitesRef
		.doc(queryParams.siteId)
		.collection(`AnchorsPosition`)
		.orderBy("created.date")
		.get()
}

export async function saveAnchorsDraggedOnDatabase(queryParams) {
	if (
		!queryParams.siteId ||
		!queryParams.marker ||
		!queryParams?.marker?.markerOfPOIsId ||
		!queryParams?.network_address ||
		!queryParams?.wirepasFloorPlanId ||
		!queryParams.oldAnchorDoc ||
		!queryParams.user
	)
		return

	const anchorsRef = sitesRef.doc(queryParams.siteId).collection("AnchorsPosition")

	// New data
	const updateData = {
		gpsData: queryParams.marker.gpsData || "",
		floorPlanId: queryParams.marker.floorPlanId || "",
		status: queryParams.marker.status || "",
		network_address: queryParams.network_address || "",
		wirepasFloorPlanId: queryParams.wirepasFloorPlanId || ""
	}

	//preparing data to update infra status and and floorplan
	// console.log("🚀 ~ saveAnchorsDraggedOnDatabase ~ queryParams:", queryParams)
	// const infraNodesRef = infraRef.doc("Wirepas").collection("Nodes").doc(queryParams?.marker?.id)

	// New data for that node in infra
	// const updateDataForInfra = {
	// 	floorPlanId: queryParams.marker.floorPlanId || "",
	// 	status: queryParams.marker.status || ""
	// }

	const anchorsLogsRef = db.collection("AnchorsPositionLogs") // Log Collection

	// Log data
	const logData = {
		docId: queryParams.marker.markerOfPOIsId,
		previousData: queryParams.oldAnchorDoc,
		updatedData: queryParams.marker,
		updated: {
			id: queryParams.user.id,
			email: queryParams.user.email,
			date: firebase.firestore.FieldValue.serverTimestamp()
		}
	}

	const updateAnchorOperation = anchorsRef
		.doc(queryParams.marker.markerOfPOIsId)
		.update(updateData) // AnchorsPosition Collection

	const updateOtherCollectionOperation = anchorsLogsRef.doc().set(logData)

	//update infra anchor
	// const updateAnchorsOnInfraOperation = infraNodesRef.update(updateDataForInfra)

	await Promise.all([
		updateAnchorOperation,
		updateOtherCollectionOperation
		// updateAnchorsOnInfraOperation
	])
}

export function getAnchorToUpdate({ id, siteId }) {
	if (!siteId || !id) {
		return Promise.resolve(null)
	}

	return sitesRef.doc(`${siteId}/AnchorsPosition/${id}`).get()
}

// Update anchor
export async function updateAnchor({
	data,
	siteId,
	user,
	newImagesUrlsArray,
	oldAnchorDoc,
	customerId
}) {
	if (!siteId || !data || !data.uuid || !user || !customerId) return

	const id = data.id.toString()

	const anchorsRef = sitesRef.doc(siteId).collection("AnchorsPosition")

	// New data
	const updateData = {
		uuid: Number(data.uuid),
		node_name: typeof data.uuid === "string" ? data.uuid : data.uuid.toString(),
		description: data.description || "",
		imagesUrls: newImagesUrlsArray,
		siteId: siteId,
		updated: {
			id: user.id,
			email: user.email,
			date: firebase.firestore.FieldValue.serverTimestamp()
		},
		status: data?.status,
		customerId: customerId
	}

	const anchorsLogsRef = db.collection("AnchorsPositionLogs") // Log Collection

	// Log data
	const logData = {
		docId: id,
		previousData: oldAnchorDoc,
		updatedData: updateData,
		updated: {
			id: user.id,
			email: user.email,
			date: firebase.firestore.FieldValue.serverTimestamp()
		}
	}

	const updateAnchorOperation = anchorsRef.doc(id).update(updateData) // AnchorsPosition Collection
	const updateOtherCollectionOperation = anchorsLogsRef.doc().set(logData)

	await Promise.all([updateAnchorOperation, updateOtherCollectionOperation])
}

// Update active anchor
export async function updateActiveAnchor({
	data,
	siteId,
	user,
	newImagesUrlsArray,
	oldAnchorDoc,
	customerId,
	wirepasFloorPlanId
}) {
	if (!siteId || !data || !data.id || !user || !wirepasFloorPlanId) return

	const id = data.id.toString()

	// const activeAnchorsRef = sitesRef.doc(siteId).collection("AnchorsActive") // not used anymore
	const nodesRef = sitesRef.doc(siteId).collection("Nodes")

	// New data
	const updateData = {
		...data,
		description: data?.description || "",
		imagesUrls: newImagesUrlsArray || [],
		status: data.status,
		updated: {
			id: user.id,
			email: user.email,
			date: firebase.firestore.FieldValue.serverTimestamp()
		},
		customerId: customerId,
		wirepasFloorPlanId: wirepasFloorPlanId
	}

	const anchorsLogsRef = db.collection("AnchorsActiveLogs") // Log Collection

	// Log data
	const logData = {
		docId: id,
		previousData: oldAnchorDoc,
		updatedData: updateData,
		updated: {
			id: user.id,
			email: user.email,
			date: firebase.firestore.FieldValue.serverTimestamp()
		}
	}

	const updateActiveAnchorOperation = nodesRef.doc(id).update(updateData)

	const updateOtherCollectionOperation = anchorsLogsRef.doc().set(logData)

	await Promise.all([updateActiveAnchorOperation, updateOtherCollectionOperation])
}

export async function unplaceAnchorsFromDatabase(queryParams) {
	if (!queryParams || !queryParams.siteId || !queryParams.user || !queryParams.ids) {
		return Promise.resolve(null)
	}

	const anchorsRef = sitesRef.doc(queryParams.siteId).collection("AnchorsPosition")
	const anchorsLogsRef = db.collection("AnchorsPositionLogs")

	const updateData = {
		approved: false,
		status: "assigned",
		updated: {
			id: queryParams.user.id,
			email: queryParams.user.email,
			date: firebase.firestore.FieldValue.serverTimestamp()
		}
	}

	const batch = db.batch()

	queryParams.ids.forEach(id => {
		batch.update(anchorsRef.doc(id), updateData)

		const logData = {
			docId: id,
			updatedData: updateData,
			updated: {
				id: queryParams.user.id,
				email: queryParams.user.email,
				date: firebase.firestore.FieldValue.serverTimestamp()
			}
		}
		batch.set(anchorsLogsRef.doc(), logData)
	})
	await batch.commit()
}

function callUpdateAnchor(params) {
	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
	myHeaders.append("Content-Type", "application/json")

	const raw = JSON.stringify({
		...params
	})

	const requestOptions = {
		method: "PUT",
		headers: myHeaders,
		body: raw,
		redirect: "follow"
	}
	return fetch(
		`${process.env.REACT_APP_CUSTOMER_API_BASE_URL_NEW_VERSION_V2}/infrastructure/update-status`,
		requestOptions
	).catch(err => {
		console.log("Error: ", err)
	})
}

export async function updateAnchorStatus(queryParams) {
	if (
		!queryParams.nodes ||
		!queryParams.status ||
		!queryParams?.siteId ||
		!queryParams?.nodeType ||
		!queryParams?.callersUserId ||
		!queryParams.oldAnchorDoc
	)
		return

	// Log data
	const logData = {
		docId: queryParams.nodes,
		previousData: queryParams.oldAnchorDoc,
		updatedData: { ...queryParams },
		updated: {
			id: queryParams.callersUserId,
			email: queryParams.callersEmail || "",
			date: firebase.firestore.FieldValue.serverTimestamp()
		}
	}

	let anchorsLogsRef = null
	let anchorsRef = null

	if (
		(queryParams.status && queryParams.status === "placed") ||
		queryParams.status === "planned" ||
		queryParams.status === "assigned"
	) {
		anchorsLogsRef = db.collection("AnchorsPositionLogs") // Log Collection
		anchorsRef = sitesRef.doc(queryParams.siteId).collection("AnchorsPosition") //Anchor Collection
	} else {
		// console.log("its a active anchor")
		anchorsLogsRef = db.collection("AnchorsActiveLogs") // Log Collection
		anchorsRef = sitesRef.doc(queryParams.siteId).collection("Nodes") //Anchor Collection
	}

	const updateOtherCollectionOperation = anchorsLogsRef.doc().set(logData)

	let end = []
	if (queryParams.imagesUrls && queryParams.imagesUrls.length > 0) {
		const updateImages = anchorsRef
			.doc(queryParams.nodes[0])
			.update({ imagesUrls: queryParams.imagesUrls })
		end = [updateOtherCollectionOperation, updateImages]
	} else end = [updateOtherCollectionOperation]

	await Promise.all(end)

	callUpdateAnchor(queryParams)

	return end.length
}

function callApproveNodes(params) {
	// console.log("🚀 ~ callApproveNodes ~ params:", params)
	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
	myHeaders.append("Content-Type", "application/json")

	const raw = JSON.stringify({
		...params
	})

	const requestOptions = {
		method: "PUT",
		headers: myHeaders,
		body: raw,
		redirect: "follow"
	}
	return fetch(
		`${process.env.REACT_APP_CUSTOMER_API_BASE_URL_NEW_VERSION_V2}/infrastructure/approve-nodes`,
		requestOptions
	).catch(err => {
		console.log("Error: ", err)
	})
}

export function approveNodes(queryParams) {
	if (!queryParams?.nodes || !queryParams?.nodeType || !queryParams?.callersUserId) return

	return callApproveNodes(queryParams)
}

// ━━━━━━━━━━━━━━━━━━━━━━━━ Sensors Position ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ \\

export function getSensorsCollection(queryParams) {
	if (!queryParams) {
		return Promise.resolve(null)
	}
	return sitesRef
		.doc(queryParams.siteId)
		.collection(`SensorsPosition`)
		.orderBy("created.date")
		.get()
}

export async function saveSensorsDraggedOnDatabase(queryParams) {
	if (
		!queryParams.siteId ||
		!queryParams.marker ||
		!queryParams?.marker?.markerOfPOIsId ||
		!queryParams?.network_address ||
		!queryParams?.wirepasFloorPlanId ||
		!queryParams.oldSensorDoc ||
		!queryParams.user
	)
		return

	const sensorsRef = sitesRef.doc(queryParams.siteId).collection("SensorsPosition")

	// New data
	const updateData = {
		gpsData: queryParams.marker.gpsData || "",
		floorPlanId: queryParams.marker.floorPlanId || "",
		status: queryParams.marker.status || "",
		network_address: queryParams.network_address || "",
		wirepasFloorPlanId: queryParams.wirepasFloorPlanId || ""
	}

	//preparing data to update infra status and and floorplan
	// console.log("🚀 ~ saveSensorsDraggedOnDatabase ~ queryParams:", queryParams)
	// const infraNodesRef = infraRef.doc("Wirepas").collection("Nodes").doc()

	const sensorsLogsRef = db.collection("SensorsPositionLogs") // Log Collection

	// Log data
	const logData = {
		docId: queryParams.marker.markerOfPOIsId,
		previousData: queryParams.oldSensorDoc,
		updatedData: queryParams.marker,
		updated: {
			id: queryParams.user.id,
			email: queryParams.user.email,
			date: firebase.firestore.FieldValue.serverTimestamp()
		}
	}

	const updateSensorOperation = sensorsRef
		.doc(queryParams.marker.markerOfPOIsId)
		.update(updateData) // SensorsPosition Collection
	const updateOtherCollectionOperation = sensorsLogsRef.doc().set(logData)

	await Promise.all([updateSensorOperation, updateOtherCollectionOperation])
}

export function getSensorToUpdate({ id, siteId }) {
	if (!siteId || !id) {
		return Promise.resolve(null)
	}

	return sitesRef.doc(`${siteId}/SensorsPosition/${id}`).get()
}

// Update sensor
export async function updateSensor({
	data,
	siteId,
	user,
	newImagesUrlsArray,
	oldSensorDoc,
	customerId
}) {
	if (!siteId || !data || !data.uuid || !user || !customerId) return

	const id = data.id.toString()

	const sensorsRef = sitesRef.doc(siteId).collection("SensorsPosition")

	// New data
	const updateData = {
		uuid: Number(data.uuid),
		node_name: typeof data.uuid === "string" ? data.uuid : data.uuid.toString(),
		description: data.description || "",
		imagesUrls: newImagesUrlsArray,
		siteId: siteId,
		updated: {
			id: user.id,
			email: user.email,
			date: firebase.firestore.FieldValue.serverTimestamp()
		},
		status: data?.status,
		customerId: customerId
	}

	const sensorsLogsRef = db.collection("SensorsPositionLogs") // Log Collection

	// Log data
	const logData = {
		docId: id,
		previousData: oldSensorDoc,
		updatedData: updateData,
		updated: {
			id: user.id,
			email: user.email,
			date: firebase.firestore.FieldValue.serverTimestamp()
		}
	}

	const updateSensorOperation = sensorsRef.doc(id).update(updateData) // SensorsPosition Collection
	const updateOtherCollectionOperation = sensorsLogsRef.doc().set(logData)

	await Promise.all([updateSensorOperation, updateOtherCollectionOperation])
}

// Update active sensor
export async function updateActiveSensor({
	data,
	siteId,
	user,
	newImagesUrlsArray,
	oldSensorDoc,
	customerId,
	wirepasFloorPlanId
}) {
	if (!siteId || !data || !data.id || !user || !wirepasFloorPlanId) return

	const id = data.id.toString()

	// const activeSensorsRef = sitesRef.doc(siteId).collection("SensorsActive") // not used anymore
	const nodesRef = sitesRef.doc(siteId).collection("Nodes")

	// New data
	const updateData = {
		...data,
		description: data?.description || "",
		imagesUrls: newImagesUrlsArray || [],
		status: data.status,
		updated: {
			id: user.id,
			email: user.email,
			date: firebase.firestore.FieldValue.serverTimestamp()
		},
		customerId: customerId,
		wirepasFloorPlanId: wirepasFloorPlanId
	}

	const sensorsLogsRef = db.collection("SensorsActiveLogs") // Log Collection

	// Log data
	const logData = {
		docId: id,
		previousData: oldSensorDoc,
		updatedData: updateData,
		updated: {
			id: user.id,
			email: user.email,
			date: firebase.firestore.FieldValue.serverTimestamp()
		}
	}

	const updateActiveSensorOperation = nodesRef.doc(id).update(updateData)

	const updateOtherCollectionOperation = sensorsLogsRef.doc().set(logData)

	await Promise.all([updateActiveSensorOperation, updateOtherCollectionOperation])
}

export async function unplaceSensorsFromDatabase(queryParams) {
	if (!queryParams || !queryParams.siteId || !queryParams.user || !queryParams.ids) {
		return Promise.resolve(null)
	}

	const sensorsRef = sitesRef.doc(queryParams.siteId).collection("SensorsPosition")
	const sensorsLogsRef = db.collection("SensorsPositionLogs")

	const updateData = {
		approved: false,
		status: "assigned",
		floorPlanId: null,
		updated: {
			id: queryParams.user.id,
			email: queryParams.user.email,
			date: firebase.firestore.FieldValue.serverTimestamp()
		}
	}

	const batch = db.batch()

	queryParams.ids.forEach(id => {
		batch.update(sensorsRef.doc(id), updateData)

		const logData = {
			docId: id,
			updatedData: updateData,
			updated: {
				id: queryParams.user.id,
				email: queryParams.user.email,
				date: firebase.firestore.FieldValue.serverTimestamp()
			}
		}
		batch.set(sensorsLogsRef.doc(), logData)
	})
	await batch.commit()
}

export function updateApprovedNodes(queryParams) {
	if (
		!queryParams?.node ||
		!queryParams?.siteId ||
		!queryParams?.callersUserId ||
		!queryParams?.customerId
	)
		return

	return callUpdateApprovedNodes(queryParams)
}
