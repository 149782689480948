import * as requestFromServer from "./DesignStudioCrud"
import { DesignStudioSlice, callTypes } from "./DesignStudioSlice"
import { profileSlice } from "../../../../redux/profile/profileSlice"
import { convertCollectionSnapshotToMap } from "../../_helpers/ActionHelpers"
import { firebaseLooper } from "../../_helpers/ActionHelpers"
import { firestoreOld } from "../../../../../firebase"
import firebase from "firebase/compat/app"
import * as turf from "@turf/turf"
import { actionTypes } from "../../../../globalUI/snackBar.duck"
const { actions } = DesignStudioSlice

export const updateUrlsFromDB = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	// console.log(queryParams, "params actions");

	const promises = []
	var features = queryParams.data.map(val => {
		const coordinates = val.geoJson.geometry.coordinates[0].map(v => {
			return new firebase.firestore.GeoPoint(v[1], v[0])
		})
		var zoneId = null

		zoneId = val.geoJson.properties.zoneId
		const zoneIdFromDB = queryParams.zoneToUpdateUrls[0].id

		return {
			...val.geoJson,
			properties: {
				name: val.name,
				type: val.type,
				capacity: val.capacity,
				zoneId: zoneId,
				zoneImagesUrls:
					zoneId === zoneIdFromDB
						? queryParams.newImagesUrls
						: val.geoJson.properties.zoneImagesUrls || null
			},
			geometry: {
				type: val.geoJson.geometry.type,
				coordinates
			}
		}
	})

	var newParams = {
		...queryParams,
		geoJson: {
			type: "FeatureCollection",
			features: features
		},
		promises
	}

	return requestFromServer
		.updateUrls(newParams)
		.then(response => {
			dispatch({
				type: "SNACKBAR_SUCCESS",
				payload: `Image deleted successfully`
			})
		})
		.catch(error => {
			console.log("Error: ", error)
			error.clientMessage = "Can't delete image"
			dispatch({
				type: "SNACKBAR_ERROR",
				payload: `Error uploading images`
			})
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const getUrlsFromDB = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }))

	if (!queryParams) {
		dispatch(actions.zonesInDBFetched({ entities: [] }))
	}

	return requestFromServer
		.getZonesInDB(queryParams)
		.then(response => {
			if (!response) {
				throw Error("Can't make a request for server")
			}

			const zones = convertCollectionSnapshotToMap(response)
			// console.log("🚀 ~ zones", zones);

			dispatch(actions.zonesInDBFetched({ entities: zones }))
		})
		.catch(error => {
			console.log("fetch zones from DB ", error)
			error.clientMessage = "Can't find  zones in DB"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

export const saveUrlsToDB = queryParams => dispatch => {
	// dispatch(actions.startCall({ callType: callTypes.action }));

	const zoneFetched = queryParams.zonesFetchedFromDB.find(
		zone => zone.id === queryParams.row.geoJson.properties.zoneId
	)

	const oldImages = zoneFetched.imagesUrls ? zoneFetched.imagesUrls : []

	let imagesSummed = [...oldImages]

	queryParams.result.forEach(res => {
		imagesSummed.push(res)
	})

	const promises = []
	var features = queryParams.data.map(val => {
		const coordinates = val.geoJson.geometry.coordinates[0].map(v => {
			return new firebase.firestore.GeoPoint(v[1], v[0])
		})
		var zoneId = null

		zoneId = val.geoJson.properties.zoneId

		return {
			...val.geoJson,
			properties: {
				name: val.name,
				type: val.type,
				capacity: val.capacity,
				zoneId: zoneId,
				zoneImagesUrls:
					zoneId === zoneFetched.id
						? imagesSummed
						: val.geoJson.properties.zoneImagesUrls || null
			},
			geometry: {
				type: val.geoJson.geometry.type,
				coordinates
			}
		}
	})

	var newParams = {
		...queryParams,
		geoJson: {
			type: "FeatureCollection",
			features: features
		},
		promises
	}

	return requestFromServer
		.saveUrls(newParams)
		.then(response => {
			dispatch({
				type: "SNACKBAR_SUCCESS",
				payload: `Images uploaded successfully`
			})
		})
		.catch(error => {
			console.log("Error: ", error)
			error.clientMessage = "Can't updload images"
			dispatch({
				type: "SNACKBAR_ERROR",
				payload: `Error uploading images`
			})
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const saveAreasAndGeoJson = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	// console.log(queryParams, "params actions add zones");

	const promises = []
	const features = queryParams.data.map(val => {
		const coordinates = val.geoJson.geometry.coordinates[0].map(v => {
			// console.log(v, "v zones");
			return new firebase.firestore.GeoPoint(v[1], v[0])
		})
		var areaId = null

		// ↓ Info and Door Markers
		// Door marker coords
		const point1 = turf.point(val.geoJson.geometry.coordinates[0][0])
		const point2 = turf.point(val.geoJson.geometry.coordinates[0][1])
		const midpoint = turf.midpoint(point1, point2)
		const midPointLng = midpoint.geometry.coordinates[0]
		const midPointLat = midpoint.geometry.coordinates[1]

		// Info marker coords inside polygon (Have a marker coords by default)
		const poly2 = turf.polygon(val.geoJson.geometry.coordinates)
		const pointOnPolygon = turf.pointOnFeature(poly2) // ← Get point inside each polygon.
		const pointOnPolygonLng = pointOnPolygon.geometry.coordinates[0]
		const pointOnPolygonLat = pointOnPolygon.geometry.coordinates[1]

		// Fetch coords if marker coords already exist on each zone and render conditionally
		// Zone in DB with same id as feature
		const fetchZoneWithMarkerCoords =
			queryParams.zonesFetchedFromDB &&
			queryParams.zonesFetchedFromDB.find(area => area.id === val.geoJson.properties.areaId)

		// Conditionally door markers
		const fetchedLngDoor = fetchZoneWithMarkerCoords?.doorMarker?.longitude
		const fetchedLatDoor = fetchZoneWithMarkerCoords?.doorMarker?.latitude
		const conditionalLngDoor = fetchedLngDoor ? fetchedLngDoor : midPointLng
		const conditionalLatDoor = fetchedLatDoor ? fetchedLatDoor : midPointLat

		// Conditionally info markers
		const fetchedLng = fetchZoneWithMarkerCoords?.marker?.longitude
		const fetchedLat = fetchZoneWithMarkerCoords?.marker?.latitude
		const conditionalLng = fetchedLng ? fetchedLng : pointOnPolygonLng
		const conditionalLat = fetchedLat ? fetchedLat : pointOnPolygonLat

		const value = {
			color: val.color,
			name: val.name,
			type: val.type,
			capacity: val.capacity,
			polygon: coordinates,
			floorPlanId: queryParams.floorPlanId,
			networkId: queryParams.networkId,
			marker: new firebase.firestore.GeoPoint(conditionalLat, conditionalLng),
			doorMarker: new firebase.firestore.GeoPoint(conditionalLatDoor, conditionalLngDoor),
			visible: val.visible,
			geoFencing: val.geoFencing,
			altitude: queryParams.floorPlan?.coords?.bottomLeftCorner?.altitude
				? queryParams.floorPlan?.coords?.bottomLeftCorner?.altitude
				: 0 // Altitude to Wirepas
			// referencePoints: val.geoJson.properties?.referencePoints || null, // Reference Points to Wirepas
		}

		if (val.type === "baseLayer") {
		} else if (val.geoJson.properties.areaId) {
			const ref = firestoreOld
				.collection("Sites")
				.doc(queryParams.networkId)
				.collection("Areas")
				.doc(val.geoJson.properties.areaId)
			areaId = val.geoJson.properties.areaId

			promises.push(ref.update(value))
			// promises.push(ref.set(value));
		} else {
			const ref = firestoreOld
				.collection("Sites")
				.doc(queryParams.networkId)
				.collection("Areas")
				.doc()
			areaId = ref.id
			promises.push(ref.set(value))
		}

		return {
			...val.geoJson,
			properties: {
				name: val.name,
				type: val.type,
				capacity: Number(val.capacity),
				areaId: areaId,
				zoneImagesUrls: val.geoJson.properties?.zoneImagesUrls
					? val.geoJson.properties?.zoneImagesUrls
					: [],
				// zoneImagesUrls: firebase.firestore.FieldValue.arrayUnion(
				//   ...queryParams.zoneImagesUrls
				// ),
				visible: val.visible,
				geoFencing: val.geoFencing,
				altitude: queryParams.floorPlan?.coords?.bottomLeftCorner?.altitude
					? queryParams.floorPlan?.coords?.bottomLeftCorner?.altitude
					: 0 // Altitude to Wirepas
				// referencePoints: val.geoJson.properties?.referencePoints || null, // Reference Points to Wirepas
			},
			geometry: {
				type: val.geoJson.geometry.type,
				coordinates
			}
		}
	})

	var newParams = {
		...queryParams,
		geoJson: {
			type: "FeatureCollection",
			features: features
		},
		promises
	}

	return requestFromServer
		.saveGeoJson(newParams)
		.then(response => {
			dispatch({
				type: "SNACKBAR_SUCCESS",
				payload: `Saved successfully`
			})
		})
		.catch(error => {
			console.log("Error: ", error)
			error.clientMessage = "Can't save"
			dispatch({
				type: "SNACKBAR_ERROR",
				payload: `Error saving`
			})
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const saveLayerTypes = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	// console.log(queryParams, "queryParams saveLayerTypes nas Actions");

	return requestFromServer
		.saveLayerTypes(queryParams)
		.then(response => {
			dispatch(profileSlice.actions.layerTypesFetched(queryParams.layerTypes))

			// doesn't dispatch success message when creating default layer type
			if (queryParams.layerTypes.length !== 1) {
				dispatch({
					type: "SNACKBAR_SUCCESS",
					payload: `Area types saved successfully`
				})
			}
		})
		.catch(error => {
			console.log("Error: ", error)
			error.clientMessage = "Can't save"
			dispatch({
				type: "SNACKBAR_ERROR",
				payload: `Error saving area types`
			})
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const deleteLayerTypes = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	return requestFromServer
		.deleteLayerTypes(queryParams)
		.then(response => {
			dispatch({
				type: "SNACKBAR_SUCCESS",
				payload: `Area type delete successfully`
			})
		})
		.catch(error => {
			console.log("Error: ", error)
			error.clientMessage = "Can't delete"
			dispatch({
				type: "SNACKBAR_ERROR",
				payload: `Error deleting area types`
			})
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const updateLayerTypes = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	// console.log(queryParams, "queryParams updateLayerTypes nas Actions");

	return requestFromServer
		.updateLayerTypes(queryParams)
		.then(response => {
			dispatch({
				type: "SNACKBAR_SUCCESS",
				payload: `Area types updated successfully`
			})
		})
		.catch(error => {
			console.log("Error: ", error)
			error.clientMessage = "Can't update"
			dispatch({
				type: "SNACKBAR_ERROR",
				payload: `Error updating area types`
			})
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const saveZoneMarkers = queryParams => dispatch => {
	// dispatch(actions.startCall({ callType: callTypes.action }));
	return requestFromServer
		.saveMarkersFromZones(queryParams)
		.then(response => {})
		.catch(error => {
			console.log("Error: ", error)
			error.clientMessage = "Can't save marker coordinates"
			dispatch({
				type: "SNACKBAR_ERROR",
				payload: `Error saving marker coordinates.`
			})
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const saveZoneDoorMarkers = queryParams => dispatch => {
	// dispatch(actions.startCall({ callType: callTypes.action }));
	return requestFromServer
		.saveDoorMarkersFromZones(queryParams)
		.then(response => {})
		.catch(error => {
			console.log("Error: ", error)
			error.clientMessage = "Can't save marker coordinates"
			dispatch({
				type: "SNACKBAR_ERROR",
				payload: `Error saving marker coordinates.`
			})
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const deleteZonesFromDB = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	// console.log(queryParams, "params actions");

	const filteredData = queryParams.data.filter(val => val.id !== queryParams.zoneToDelete.id)

	var features = filteredData.map(val => {
		const coordinates = val.geoJson.geometry.coordinates[0].map(v => {
			return new firebase.firestore.GeoPoint(v[1], v[0])
		})

		return {
			...val.geoJson,
			properties: {
				name: val.name,
				type: val.type,
				capacity: val.capacity,
				areaId: val.geoJson.properties.areaId,
				zoneImagesUrls: val.geoJson.properties.zoneImagesUrls || null
			},
			geometry: {
				type: val.geoJson.geometry.type,
				coordinates
			}
		}
	})

	// console.log(filteredData, "filteredData");
	// console.log(features, "features");

	var newParams = {
		...queryParams,
		geoJson: {
			type: "FeatureCollection",
			features: features
		}
	}

	return requestFromServer
		.deleteZones(newParams)
		.then(response => {})
		.catch(error => {
			console.log("Error: ", error)
			error.clientMessage = "Can't delete"
			dispatch({
				type: "SNACKBAR_ERROR",
				payload: `Error deleting areas`
			})
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const fetchResourcesMeetings = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }))

	if (!queryParams) {
		dispatch(actions.resourcesMeetingsFetched({ entities: [] }))
	}

	return requestFromServer
		.getResourcesMeetings(queryParams)
		.then(response => {
			if (!response) {
				throw Error("Can't make a request for server")
			}

			const resources = convertCollectionSnapshotToMap(response)
			// console.log(resources, "resources");
			dispatch(actions.resourcesMeetingsFetched({ entities: resources }))
		})
		.catch(error => {
			console.log("fetch resources meetings", error)
			error.clientMessage = "Can't find resources meetings"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

//★━━━━━━━━━★ Points of Interest ACTIONS ★━━━━━━━━━★\\
//★━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━★\\

export const createPointOfInterest = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	return requestFromServer
		.createPointOfInterest(queryParams)
		.then(res => {
			dispatch(
				actions.poiCreated({
					name: queryParams.data.name,
					description: queryParams.data.description,
					type: queryParams.data.type,
					imagesUrls: queryParams.imagesUrls,
					siteId: queryParams.siteId,
					created: {
						id: queryParams.user.id,
						email: queryParams.user.email,
						date: firebase.firestore.FieldValue.serverTimestamp()
					},
					floorPlanId: queryParams.floorPlanId,
					coords: queryParams.coords,
					markerVisible: queryParams.markerVisible,
					id: res || ""
				})
			)
			dispatch({
				type: "SNACKBAR_SUCCESS",
				payload: `Point of Interest created`
			})
			return res
		})
		.catch(error => {
			error.clientMessage = "Can't create Point of Interest"
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const updatePointOfInterest = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	return requestFromServer
		.updatePointOfInterest(queryParams)
		.then(res => {
			const updatedPOI = {
				...queryParams.data,
				name: queryParams.data.name,
				description: queryParams.data.description,
				type: queryParams.data.type,
				imagesUrls: queryParams.newImagesUrlsArray,
				siteId: queryParams.siteId,
				updated: {
					id: queryParams.user.id,
					email: queryParams.user.email,
					date: firebase.firestore.FieldValue.serverTimestamp()
				},
				markerVisible: queryParams.data.markerVisible
			}

			dispatch(actions.poiEdited(updatedPOI))

			dispatch({
				type: "SNACKBAR_SUCCESS",
				payload: `Point of Interest updated`
			})
			return res
		})
		.catch(error => {
			console.log(error, "error")
			error.clientMessage = "Can't update Point of Interest"
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const savePointsOfInterest = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	return requestFromServer
		.savePointsOfInterest(queryParams)
		.then(() => {
			dispatch({
				type: "SNACKBAR_SUCCESS",
				payload: `Points of Interest saved`
			})
		})
		.catch(error => {
			error.clientMessage = "Can't save Points of Interest"
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const savePOIsSingleMarkers = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	return requestFromServer
		.saveMarkersFromPOIs(queryParams)
		.then(response => {
			const updatedPOI = {
				...queryParams.marker,
				coords: queryParams.marker.coords || "",
				floorPlanId: queryParams.marker.floorPlanId || ""
			}

			dispatch(actions.poiEdited(updatedPOI))
		})
		.catch(error => {
			console.log("Error: ", error)
			error.clientMessage = "Can't save marker coordinates"
			dispatch({
				type: "SNACKBAR_ERROR",
				payload: `Error saving marker coordinates.`
			})
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

// export const savePOIsMarkerVisible = (queryParams) => (dispatch) => {
//   dispatch(actions.startCall({ callType: callTypes.action }));

//   return requestFromServer
//     .saveMarkerVisibilityFromPOIs(queryParams)
//     .then((response) => {})
//     .catch((error) => {
//       console.log("Error: ", error);
//       error.clientMessage = "Can't save marker visibility";
//       dispatch({
//         type: "SNACKBAR_ERROR",
//         payload: `Error saving marker visibility.`,
//       });
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//     });
// };

export const fetchPointsOfInterest = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }))

	if (!queryParams) {
		dispatch(actions.pointsOfInterestFetched({ entities: [] }))
	}

	return requestFromServer
		.getPointsOfInterest(queryParams)
		.then(response => {
			if (!response) {
				throw Error("Can't make a request for server")
			}

			const pointsOfInterest = convertCollectionSnapshotToMap(response)

			dispatch(actions.pointsOfInterestFetched({ entities: pointsOfInterest }))
		})
		.catch(error => {
			console.log("fetch points of interest ", error)
			error.clientMessage = "Can't find points of interest"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

export const deletePointOfInterest = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	return requestFromServer
		.deletePointOfInterest(queryParams)
		.then(response => {
			dispatch(actions.pointOfInterestDeleted({ id: queryParams.id }))

			dispatch({
				type: "SNACKBAR_SUCCESS",
				payload: `Point of Interest ${queryParams.name} deleted`
			})
		})
		.catch(error => {
			error.clientMessage = "Can't delete product"
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const fetchToUpdatePointOfInterestAction = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	if (!queryParams) {
		dispatch(actions.pointOfInterestFetchedToUpdate({ entities: [] }))
	}

	return requestFromServer
		.getPointOfInterestToUpdate(queryParams)
		.then(response => {
			if (!response) {
				throw Error("Can't make a request for server")
			}

			const pointOfInterestResponse = response.data()
			const pointOfInterest = {
				...pointOfInterestResponse,
				id: queryParams.id
			}

			dispatch(
				actions.pointOfInterestFetchedToUpdate({
					entities: pointOfInterest
				})
			)
		})
		.catch(error => {
			console.log("can't fetch point of interest to update ", error)
			error.clientMessage = "Can't find point of interest to update"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

// ━━━━━━━━━━━━━━━━━━━━━━━━ Anchors Position ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ \\

export const fetchAnchorsPosition = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }))

	if (!queryParams) {
		dispatch(actions.anchorsPositioningFetched({ entities: [] }))
	}

	return requestFromServer
		.getAnchorsCollection(queryParams)
		.then(response => {
			if (!response) {
				throw Error("Can't make a request for server")
			}

			const anchorsFromDatabase = convertCollectionSnapshotToMap(response)

			dispatch(actions.anchorsPositioningFetched({ entities: anchorsFromDatabase }))
		})
		.catch(error => {
			console.log("fetch anchors", error)
			error.clientMessage = "Can't find anchors position"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

export const saveAnchorSingleMarkers = queryParams => dispatch => {
	// console.log("🚀 ~ saveAnchorSingleMarkers ~ queryParams:", queryParams)
	dispatch(actions.startCall({ callType: callTypes.action }))

	return requestFromServer
		.saveAnchorsDraggedOnDatabase(queryParams)
		.then(response => {
			// console.log("🚀 ~ saveAnchorSingleMarkers ~ response:", response)

			if (response > 0) {
				// dispatch({
				// 	type: "SNACKBAR_SUCCESS",
				// 	payload: `Saved new anchor coordinates.`
				// })
				return
			}

			return response.json()
		})
		.then(result => {
			// console.log("🚀 ~ saveAnchorSingleMarkers ~ result:", result)
			return
		})
		.catch(error => {
			console.log("Error: ", error)
			error.clientMessage = "Can't save anchor marker coordinates"
			dispatch({
				type: "SNACKBAR_ERROR",
				payload: `Error saving anchor marker coordinates.`
			})
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const fetchToUpdateAnchorAction = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	if (!queryParams) {
		dispatch(actions.anchorPositionFetchedToUpdate({ entities: [] }))
	}

	return requestFromServer
		.getAnchorToUpdate(queryParams)
		.then(response => {
			if (!response) {
				throw Error("Can't make a request for server")
			}

			const anchorsResponse = response.data()
			const newAnchorsData = {
				...anchorsResponse,
				// id: queryParams.id
				id: queryParams.uuid
			}

			dispatch(
				actions.anchorPositionFetchedToUpdate({
					entities: newAnchorsData
				})
			)
		})
		.catch(error => {
			console.log("can't fetch Anchors to update ", error)
			error.clientMessage = "Can't find Anchors to update"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

// update anchor
export const updateAnchorPosition = queryParams => dispatch => {
	// console.log("🚀 ~ updateAnchorPosition ~ queryParams:", queryParams)
	dispatch(actions.startCall({ callType: callTypes.action }))

	return requestFromServer
		.updateAnchor(queryParams)
		.then(res => {
			dispatch({
				type: "SNACKBAR_SUCCESS",
				payload: `Anchor updated`
			})
			return res
		})
		.catch(error => {
			console.log(error, "error")
			error.clientMessage = "Can't update Anchor"
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

// update active anchor
export const updateActiveAnchorPosition = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	return requestFromServer
		.updateActiveAnchor(queryParams)
		.then(res => {
			// console.log("🚀 ~ updateActiveAnchorPosition ~ res:", res)

			dispatch({
				type: "SNACKBAR_SUCCESS",
				payload: `Active anchor successfully updated.`
			})
			return res
		})
		.catch(error => {
			console.log(error, "error")
			error.clientMessage = "Can't update Active anchor."
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const unplaceAnchors = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	return requestFromServer
		.unplaceAnchorsFromDatabase(queryParams)
		.then(response => {
			dispatch(actions.anchorsUnplaced({ ids: queryParams.ids }))

			dispatch({
				type: "SNACKBAR_SUCCESS",
				payload: `Unplaced successfully.`
			})
			// console.log("🚀 ~ unplaceAnchors ~ response:", response)
		})
		.catch(error => {
			error.clientMessage = "Can't delete several anchors"
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const updateAnchor = queryParams => dispatch => {
	// console.log("🚀 ~ updateAnchor ~ queryParams:", queryParams)
	dispatch(actions.startCall({ callType: callTypes.action }))

	return requestFromServer
		.updateAnchorStatus(queryParams)
		.then(res => {
			// dispatch({
			// 	type: "SNACKBAR_SUCCESS",
			// 	payload: `Anchor updated`
			// })
			dispatch({
				type: "SNACKBAR_SUCCESS",
				payload: `Anchor updated`
			})
			return res
		})
		.catch(error => {
			console.log(error, "error")
			error.clientMessage = "Can't update Anchor"
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const approveNodes = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	return requestFromServer
		.approveNodes(queryParams)
		.then(response => {
			if (!response) throw Error("Can't make a request for server")
			return response.json()
		})
		.then(result => {
			return result
		})
		.catch(error => {
			// console.log("🪲 ~ getNodes ~ error:", error)
			console.log("Get Nodes: ", error)
			error.clientMessage = "Can't get nodes"
			dispatch({
				type: "SNACKBAR_ERROR",
				payload: `Anchor not updated. Please try again later.`
			})
			dispatch(actions.catchError({ error, callType: callTypes.action }))
			return error
			// queryParams.onHide()
		})
}

// ━━━━━━━━━━━━━━━━━━━━━━━━ Sensors Position ━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━ \\

export const fetchSensorsPosition = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.list }))

	if (!queryParams) {
		dispatch(actions.sensorsPositioningFetched({ entities: [] }))
	}

	return requestFromServer
		.getSensorsCollection(queryParams)
		.then(response => {
			if (!response) {
				throw Error("Can't make a request for server")
			}

			const sensorsFromDatabase = convertCollectionSnapshotToMap(response)

			dispatch(actions.sensorsPositioningFetched({ entities: sensorsFromDatabase }))
		})
		.catch(error => {
			console.log("fetch sensors", error)
			error.clientMessage = "Can't find sensors position"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

export const saveSensorSingleMarkers = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	return requestFromServer
		.saveSensorsDraggedOnDatabase(queryParams)
		.then(response => {})
		.catch(error => {
			console.log("Error: ", error)
			error.clientMessage = "Can't save sensor marker coordinates"
			dispatch({
				type: "SNACKBAR_ERROR",
				payload: `Error saving sensor marker coordinates.`
			})
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const fetchToUpdateSensorAction = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	if (!queryParams) {
		dispatch(actions.sensorPositionFetchedToUpdate({ entities: [] }))
	}

	return requestFromServer
		.getSensorToUpdate(queryParams)
		.then(response => {
			if (!response) {
				throw Error("Can't make a request for server")
			}

			const sensorsResponse = response.data()
			const newSensorsData = {
				...sensorsResponse,
				// id: queryParams.id
				id: queryParams.uuid
			}

			dispatch(
				actions.sensorPositionFetchedToUpdate({
					entities: newSensorsData
				})
			)
		})
		.catch(error => {
			console.log("can't fetch Sensors to update ", error)
			error.clientMessage = "Can't find Sensors to update"
			dispatch(actions.catchError({ error, callType: callTypes.list }))
		})
}

// update sensor
export const updateSensorPosition = queryParams => dispatch => {
	// console.log("🚀 ~ updateSensorPosition ~ queryParams:", queryParams)
	dispatch(actions.startCall({ callType: callTypes.action }))

	return requestFromServer
		.updateSensor(queryParams)
		.then(res => {
			dispatch({
				type: "SNACKBAR_SUCCESS",
				payload: `Sensor updated`
			})
			return res
		})
		.catch(error => {
			console.log(error, "error")
			error.clientMessage = "Can't update Sensor"
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

// update active sensor
export const updateActiveSensorPosition = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	return requestFromServer
		.updateActiveSensor(queryParams)
		.then(res => {
			dispatch({
				type: "SNACKBAR_SUCCESS",
				payload: `Active sensor successfully updated.`
			})
			return res
		})
		.catch(error => {
			console.log(error, "error")
			error.clientMessage = "Can't update Active sensor."
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const unplaceSensors = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))
	return requestFromServer
		.unplaceSensorsFromDatabase(queryParams)
		.then(response => {
			dispatch(actions.sensorsUnplaced({ ids: queryParams.ids }))

			dispatch({
				type: "SNACKBAR_SUCCESS",
				payload: `Unplaced successfully.`
			})
		})
		.catch(error => {
			error.clientMessage = "Can't delete several sensors"
			dispatch(actions.catchError({ error, callType: callTypes.action }))
		})
}

export const updateApprovedNodes = queryParams => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }))

	return requestFromServer
		.updateApprovedNodes(queryParams)
		.then(response => {
			if (!response) throw Error("Can't make a request for server")
			return response.json()
		})
		.then(result => {
			if (result.success)
				dispatch({
					type: "SNACKBAR_SUCCESS",
					payload: result.message
				})
			else
				dispatch({
					type: "SNACKBAR_ERROR",
					payload: result.message
				})
			return result
		})
		.catch(error => {
			dispatch({
				type: "SNACKBAR_ERROR",
				payload: "Error updating active anchor. Please try again later."
			})
			dispatch(actions.catchError({ error, callType: callTypes.action }))
			return error
		})
}
