import React, { Suspense, lazy, useEffect, useState } from "react"
import { Redirect, Switch, Route } from "react-router-dom"
import { LayoutSplashScreen, ContentRoute } from "./layout"
import { DashboardPage } from "./pages/DashboardPage"
import { OperationsTab } from "./pages/OperationsTab"
import { ReportingPage } from "./modules/Reporting/ReportingPage"
import { BookingPage } from "./modules/Booking/BookingPage"
import { PlatformUsersCard } from "./pages/platform-users/PlatformUsersCard"
import { useSelector, useDispatch, shallowEqual } from "react-redux"
import SnackBar from "./globalUI/snackBar/SnackBar"
import * as actions from "./redux/basePage/basePageActions"
import { basePageSlice } from "./redux/basePage/basePageSlice"
import ReactGa from "react-ga"
import { firestoreOld, messaging, storage } from "../firebase"
import { collection, query, where, onSnapshot, or } from "firebase/firestore"

import { MapTestPage } from "./modules/Test/MapTestPage"
import Demo from "./Demo"
import { Modal } from "react-bootstrap"
import { Bold } from "./_partials/typography/Bold"
import { IconButton } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close"
import { ContactTracingPage } from "./modules/Admin/contact-tracing/ContactTracingPage"
import { enUS, enGB, pt, fr, de } from "date-fns/locale"
import { setDefaultLocale } from "react-datepicker"
import { getToken } from "firebase/messaging"

const AdminPage = lazy(() => import("./modules/Admin/AdminPage"))
const UserProfilepage = lazy(() => import("./modules/UserProfile/UserProfilePage"))

export default function BasePage() {
	const dispatch = useDispatch()

	const {
		userClaims,
		selectedCustomer,
		selectedSite,
		selectedLocation,
		user,
		resources,
		globalOptions,
		messagingSwitch,
		notificationSwitch
	} = useSelector(
		state => ({
			userClaims: state.auth.claims,
			selectedCustomer: state.profile?.currentCustomer,
			selectedSite: state.profile?.currentSite,
			selectedLocation: state.profile?.currentLocation,
			user: state.auth?.user,
			resources: state.basePage?.resources,
			globalOptions: state.basePage?.globalOptions,
			messagingSwitch: state.notifications?.messagingSwitch,
			notificationSwitch: state.notifications?.notificationSwitch
		}),
		shallowEqual
	)

	// Set react-datepicker default locale
	useEffect(() => {
		const supportedLocales = {
			"en-US": enUS,
			"en-GB": enGB,
			pt: pt,
			fr: fr,
			de: de
			// Add more locales as needed
		}
		const browserLocale = navigator.language
		const defaultLocale = supportedLocales[browserLocale] || enUS
		setDefaultLocale(defaultLocale)
	}, [])

	useEffect(() => {
		if (globalOptions) return
		dispatch(actions.fetchGlobalOptions())
	}, [])

	useEffect(() => {
		dispatch(
			actions.setRolesAndAssetTypes({
				customer: selectedCustomer,
				roles: [],
				types: globalOptions?.defaultAssetTypes
			})
		)
	}, [selectedCustomer, globalOptions])

	// Define tagSeenTimeout based on multiple places
	useEffect(() => {
		dispatch(
			actions.setTagSeenTimeout({
				selectedSite,
				globalOptions
			})
		)
	}, [selectedSite, globalOptions])

	useEffect(() => {
		if (!selectedCustomer || !selectedSite) return

		const peopleRef = collection(firestoreOld, `Customers/${selectedCustomer.id}/People`)
		const orWhere = or(where("siteId", "==", selectedSite.id), where("siteId", "==", false))

		const q = query(peopleRef, orWhere)

		const unsubscribe = onSnapshot(q, snapshot => {
			const data = snapshot.docs.map(doc => ({
				id: doc.id,
				...doc.data()
			}))

			updatePeopleAndAssetsWithImages(data, dispatch)
				.then(response => {
					dispatch(basePageSlice.actions.peopleFetched(response || []))
				})
				.catch(error => {
					console.error("Error updating people and assets with images:", error)
				})
		})

		return () => {
			unsubscribe()
		}
	}, [selectedCustomer, selectedSite])

	useEffect(() => {
		if (!selectedCustomer || !selectedSite) return

		const peopleRef = collection(firestoreOld, `Customers/${selectedCustomer.id}/Assets`)
		const orWhere = or(where("siteId", "==", selectedSite.id), where("siteId", "==", false))
		// const orWhere = or(
		// 	where("siteId", "in", [selectedSite.id, false, null]),
		// 	where("siteId", "==", undefined)
		// )

		const q = query(peopleRef, orWhere)

		const unsubscribe = onSnapshot(q, snapshot => {
			const data = snapshot.docs.map(doc => ({
				id: doc.id,
				...doc.data()
			}))

			updatePeopleAndAssetsWithImages(data, dispatch)
				.then(response => {
					dispatch(basePageSlice.actions.assetsFetched(response || []))
				})
				.catch(error => {
					console.error("Error updating assets with images:", error)
				})
		})

		return () => {
			unsubscribe()
		}
	}, [selectedCustomer, selectedSite])

	useEffect(() => {
		if (!selectedSite) return
		dispatch(
			actions.fetchFloorPlans({
				siteId: selectedSite?.id
			})
		)
		dispatch(
			actions.fetchAreas({
				siteId: selectedSite?.id
			})
		)

		dispatch(
			actions.fetchPointsOfInterest({
				siteId: selectedSite?.id
			})
		)
	}, [selectedCustomer, selectedSite])

	// useEffect(() => {
	// 	if (selectedCustomer && user) {
	// 		dispatch(
	// 			actions.fetchDepartments({
	// 				customerId: selectedCustomer.id,
	// 				userId: user.id
	// 			})
	// 		)
	// 	}
	// }, [selectedCustomer, user])

	// useEffect(() => {
	// 	if (selectedCustomer && selectedLocation) {
	// 		dispatch(
	// 			actions.fetchResources({
	// 				customer: selectedCustomer.customerId,
	// 				location: selectedLocation.netId
	// 			})
	// 		)
	// 	}
	// }, [selectedCustomer, selectedLocation])

	// useEffect(() => {
	// 	if (selectedCustomer && resources) {
	// 		const startOfMonth = moment(firebase.firestore.Timestamp.now().toDate())
	// 			.startOf("month")
	// 			.toDate()
	// 		const endOfMonth = moment(firebase.firestore.Timestamp.now().toDate())
	// 			.endOf("month")
	// 			.add(1, "month")
	// 			.toDate()

	// 		Promise.all(
	// 			resources.map(val => {
	// 				return firestoreOld
	// 					.collection(`Customers/${selectedCustomer.customerId}/Bookings`)
	// 					.where("deleted", "==", false)
	// 					.where("resource", "==", val.id)
	// 					.where("date", ">=", startOfMonth)
	// 					.where("date", "<=", endOfMonth)
	// 					.get()
	// 			})
	// 		).then(results => {
	// 			const _bookings = results
	// 				.map(snapshot => {
	// 					return snapshot.docs.map(docSnapshot => {
	// 						return {
	// 							...docSnapshot.data(),
	// 							id: docSnapshot.id
	// 						}
	// 					})
	// 				})
	// 				.flat()
	// 			dispatch(
	// 				basePageSlice.actions.bookingsFetched({
	// 					entities: _bookings
	// 				})
	// 			)
	// 		})
	// 	}
	// }, [selectedCustomer, resources])

	useEffect(() => {
		//Google analitycs
		ReactGa.initialize("UA-163381689-7")
		ReactGa.pageview(window.location.pathname + window.location.search)
	}, [])

	const [notificationModal, setNotificationModal] = useState()

	function onHide() {
		setNotificationModal()
	}

	useEffect(() => {
		if (!selectedCustomer) return

		handleTopicSubscriptions({
			notificationsEnabled: notificationSwitch,
			messagesEnabled: messagingSwitch,
			sites: selectedCustomer?.sites || [],
			userId: user?.id
		})
	}, [selectedCustomer, notificationSwitch, messagingSwitch])

	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<SnackBar />

			<Demo>
				{!selectedCustomer && <h3>Select Customer</h3>}
				{/* {!selectedCustomer ? <h3>Select Customer</h3> :
        {!selectedLocation ? <h3>Select Location</h3> :
          !selectedLevel && <h3>Select Level</h3> }} */}

				{selectedCustomer && (
					<>
						<Modal
							show={notificationModal && true}
							onHide={onHide}
							contentClassName="bg-transparent"
							backdrop={false}
							size="sm"
						>
							<Modal.Body
								style={{
									padding: "0",
									backgroundColor: "#ECF1F4",
									borderRadius: "10px",
									overflow: "hidden"
								}}
							>
								<div className="pl-7 pr-7 pb-7 pt-2">
									{/* ━━★  TITLE  ★━━ */}
									<div className="d-flex align-items-center justify-content-between">
										<div>
											<Bold>{notificationModal?.title}</Bold>
										</div>
										<div>
											<IconButton onClick={onHide} style={{ left: "12px" }}>
												<CloseIcon />
											</IconButton>
										</div>
									</div>

									{/* ━━★  BODY  ★━━ */}
									<div>{notificationModal?.body}</div>
								</div>
							</Modal.Body>
						</Modal>
						<Switch>
							{
								/* Redirect from root URL to /dashboard. */
								<Redirect exact from="/" to="/dashboard" />
							}
							{userClaims?.isSuper && (
								<ContentRoute path="/maptest" component={MapTestPage} />
							)}

							{/* ContactTracingPage */}
							{userClaims?.profileLvl >= 4 && (
								<ContentRoute path="/audit-tool" component={ContactTracingPage} />
							)}

							<ContentRoute path="/dashboard" component={DashboardPage} />
							<ContentRoute path="/reporting" component={ReportingPage} />
							{selectedLocation?.customerType === "office" && (
								<ContentRoute path="/booking" component={BookingPage} />
							)}
							<Route path="/user-profile" component={UserProfilepage} />

							{(userClaims?.isSuper || userClaims?.isAdmin) && (
								<Route path="/admin" component={AdminPage} />
							)}

							{userClaims?.isSuper && <Route path="/operations" component={OperationsTab} />}

							{userClaims?.isSuper && (
								<ContentRoute path="/platform-users" component={PlatformUsersCard} />
							)}

							{/* <ContentRoute path='/employees' component={Employees} />
          <ContentRoute path='/builder' component={BuilderPage} />
          <ContentRoute path='/my-page' component={MyPage} /> */}

							<Redirect to="error/error-v1" />
						</Switch>
					</>
				)}
			</Demo>
		</Suspense>
	)
}

async function updatePeopleAndAssetsWithImages(data, dispatch) {
	if (!data) return null

	const images = await Promise.allSettled(
		data.map(async element => {
			let picturePath = element?.personalData?.picture || element?.picture

			if (!picturePath) return Promise.resolve(null)

			return storage
				.ref(picturePath)
				.getDownloadURL()
				.catch(err => console.log("Error: ", err))
		})
	)

	if (images) {
		return data.map((val, index) => {
			return {
				...val,
				pictureUrl: images[index]?.status === "fulfilled" ? images[index].value : null
			}
		})
	}
}

async function handleTopicSubscriptions({ notificationsEnabled, messagesEnabled, sites, userId }) {
	const permission = await Notification.requestPermission()
	if (permission !== "granted") {
		console.error("Unable to get permission to notify.")
		return
	}

	const currentToken = await getToken(messaging, {
		vapidKey: process.env.REACT_APP_FIREBASE_MESSAGING_VAPID_KEY
	}).catch(error => {
		console.error(`An error occurred while retrieving token. ${error}`)
	})

	if (!currentToken) {
		console.error("No registration token available.")
		return
	}

	var promises = []

	// Subscribe to geofencing for all sites
	//TODO This should be selectable per site and per group in a setting page
	sites.forEach(site => {
		const siteId = site.id
		if (!siteId) return

		var topicName = `${siteId}-geofencing`

		promises.push(
			topicFetch({
				operation: notificationsEnabled ? "subscribe" : "unsubscribe",
				currentToken,
				topicName
			})
		)
	})

	// Subscribe to messages
	var topicName = `${userId}-messages`
	promises.push(
		topicFetch({
			operation: messagesEnabled ? "subscribe" : "unsubscribe",
			currentToken,
			topicName
		})
	)

	return Promise.all(promises)
	// const responses = await Promise.all(promises)
	// const results = await Promise.all(responses.map(val => val.json()))
	// console.log("🚀 . results:", results)

	// if (notificationsGeofencing) {
	// 	const topic = "geofencing"
	// 	const subscribeUrl = `https://iid.googleapis.com/iid/v1/${currentToken}/rel/topics/${topic}`
	// 	fetch(subscribeUrl, {
	// 		method: "POST",
	// 		headers: new Headers({
	// 			"Content-Type": "application/json",
	// 			Authorization: `key=${process.env.REACT_APP_FIREBASE_MESSAGING_SERVER_KEY}`
	// 		})
	// 	})
	// 		.then(response => response.text())
	// 		.then(() => {
	// 			//! Check if response was not error (Sometimes it returns 401)
	// 			// console.log("🚀 . result", result)

	// 			console.log(`Subscribed to topic: ${topic}`)
	// 		})
	// 		.catch(error => {
	// 			console.error(`Error subscribing to topic: ${error}`)
	// 		})
	// } else {
	// 	const topic = "geofencing"
	// 	const subscribeUrl = `https://iid.googleapis.com/iid/v1/${currentToken}/rel/topics/${topic}`
	// 	fetch(subscribeUrl, {
	// 		method: "DELETE",
	// 		headers: new Headers({
	// 			"Content-Type": "application/json",
	// 			Authorization: `key=${process.env.REACT_APP_FIREBASE_MESSAGING_SERVER_KEY}`
	// 		})
	// 	})
	// 		.then(response => response.text())
	// 		.then(() => {
	// 			//! Check if response was not error (Sometimes it returns 401)
	// 			// console.log("🚀 . result", result)

	// 			console.log(`Unsubscribed to topic: ${topic}`)
	// 		})
	// 		.catch(error => {
	// 			console.error(`Error unsubscribing to topic: ${error}`)
	// 		})
	// }

	// const subscribeUrl = `https://iid.googleapis.com/iid/v1/${currentToken}/rel/topics/${user.id}-messages`
	// fetch(subscribeUrl, {
	// 	method: "POST",
	// 	headers: new Headers({
	// 		"Content-Type": "application/json",
	// 		Authorization: `key=${process.env.REACT_APP_FIREBASE_MESSAGING_SERVER_KEY}`
	// 	})
	// })
	// 	.then(response => response.text())
	// 	.then(() => {
	// 		//! Check if response was not error (Sometimes it returns 401)
	// 		// console.log("🚀 . result", result)

	// 		console.log(`Subscribed to messages`)
	// 	})
	// 	.catch(error => {
	// 		console.error(`Error subscribing to topic: ${error}`)
	// 	})
}

function topicFetch({ operation, currentToken, topicName }) {
	var myHeaders = new Headers()
	myHeaders.append("X-API-Key", process.env.REACT_APP_API_GATEWAY_KEY)
	myHeaders.append("Content-Type", "application/json")

	const raw = JSON.stringify({
		operation: operation,
		messagingToken: currentToken,
		topicName: topicName
	})

	const requestOptions = {
		method: "POST",
		headers: myHeaders,
		body: raw,
		redirect: "follow"
	}

	return fetch(
		`${process.env.REACT_APP_API_GATEWAY_BASE_URL}/topic-subscriptions`,
		requestOptions
	).catch(err => {
		console.error(err)
	})
}
