import React, { useState, useEffect, useRef } from "react"
import ReactDOM from "react-dom"
import firebase from "firebase/compat/app"
import { storage } from "../../../../../firebase"
import { Card, CardBody, CardHeader } from "../../../../_partials/Card"
import { useSelector, useDispatch, shallowEqual } from "react-redux"
import imageCompression from "browser-image-compression"
import { OverlayTrigger, Tooltip, Carousel } from "react-bootstrap"
import { uuidv4 } from "../../../Admin/designStudio/UIHelpers"
import MapControlsButtons from "../../../Admin/designStudio/MapControlButtons/MapControlsButtons"
import { profileSlice } from "../../../../redux/profile/profileSlice"
import { anchorUnplaced } from "../../../_redux/admin/designStudio/DesignStudioSlice"
import mapboxgl from "!mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import "@turf/angle"
import "../../../Admin/designStudio/DesignStudio.css"
import * as poiActions from "../../../_redux/admin/designStudio/DesignStudioActions"
import { addGeoJsonLayerToMap } from "../../../Admin/designStudio/_helpers/ActionHelpers"
import MapDimensions from "../../../Admin/designStudio/MapControlButtons/MapDimensions"
import DeleteModal from "./modals/DeleteModal"
import ChangeFloorControlNew from "../../../Admin/designStudio/MapControlButtons/ChangeFloorControlNew"
import {
	startButtonProgress,
	finishButtonProgress,
	addNodesToMap
} from "../map-infra-helpers/NodesHelpers"

import PopupStickOnHoverManageInfrastructure from "../map-infra-helpers/PopupStickOnHoverManageInfrastructure"
import { Button } from "../../../../_partials/Button"
import { BiEditAlt } from "react-icons/bi"

import NodesTable from "../map-infra-helpers/NodesTable"

import dragSvg from "../../../../assets/designStudio/drag.svg"

import EditNodeModal from "../map-infra-helpers/EditNodeModal"
import EditActiveNodeModal from "../map-infra-helpers/EditActiveNodeModal"

import WebcamModal from "../map-infra-helpers/WebcamModal"

import BigImageModal from "../map-infra-helpers/BigImageModal"

import DrawerAnchors from "./AnchorsControls/DrawerAnchors"
import DrawerListAnchors from "./AnchorsControls/DrawerListAnchors"

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN

const { actions } = profileSlice

export function AnchorsPositioningCard({ setCollectionChanged }) {
	const mapcontainerref = useRef()
	const dispatch = useDispatch()

	const {
		selectedCustomer,
		selectedCustomerId,
		currentFloorPlan,
		selectedSite,
		floorPlanData,
		roomTypes,
		user,
		anchorsPositionData,
		anchorsPositionToUpdate,
		anchorsData,
		modelsInventory
	} = useSelector(
		state => ({
			selectedCustomer: state.profile?.currentCustomer,
			selectedCustomerId: state.profile?.currentCustomer?.id,
			selectedSite: state.profile?.currentSite, // new selectedLocation
			currentFloorPlan: state.profile?.currentFloorPlan, // new selectedLevel
			floorPlanData: state.basePage?.floorPlans,
			roomTypes: state.profile?.currentCustomer?.layerTypes,
			user: state.auth.user,
			anchorsPositionData: state.designStudio?.anchorsPosition,
			anchorsPositionToUpdate: state.designStudio?.anchorsPositionToUpdate,
			anchorsData: state.designStudio.anchors || [],
			modelsInventory: state.inventory.modelsInventory
		}),
		shallowEqual
	)

	//━━━━━━━━ States ━━━━━━━━\\
	const [progress, setProgress] = useState(0)
	const [idsToUnplace, setIdsToUnplace] = useState([])
	const [showCreatePOIs, setShowCreatePOIs] = useState(false)
	const [poiIdToDelete, setPoiIdToDelete] = useState()
	const [showDeleteModal, setShowDeleteModal] = useState(false)
	const [urlsToDelete, setUrlsToDelete] = useState([])
	const [imagesModal2, setImagesModal2] = useState([])
	const [showModal2, setShowModal2] = useState(false) // show edit modal
	const [poiToUpdate, setPoiToUpdate] = useState()
	const [showEdit, setShowEdit] = useState(false)
	const [markers, setMarkers] = useState()
	const [anchorsPositioning, setAnchorsPositioning] = useState(anchorsPositionData) // anchors data (old pointsOfInterest state)
	const [urlsModal2, setUrlsModal2] = useState([])
	const [allImagesInStorage, setAllImagesInStorage] = useState([])
	const [checked, setChecked] = useState(false)
	const [map, setMap] = useState()
	const [floorsControl, setFloorsControl] = useState()
	const [showWebcamModal, setShowWebcamModal] = useState(false)
	const [showWebcamUpdateModal, setShowWebcamUpdateModal] = useState(false)
	const [urlsWebcamToUpdate, setUrlsWebcamToUpdate] = useState([])
	const [showAnchorsOnline, setShowAnchorsOnline] = useState(true)
	const [showAnchorsRemoved, setShowAnchorsRemoved] = useState(true)
	const [showAnchorsLost, setShowAnchorsLost] = useState(true)
	const [showAnchorsUid, setShowAnchorsUid] = useState(false)
	const [anchorsMarkers, setAnchorsMarkers] = useState()
	const [oldAnchorDoc, setOldAnchorDoc] = useState()
	const [showActiveModal, setShowActiveModal] = useState(false)
	const [activeAnchors, setActiveAnchors] = useState() // anchors data arriving from the WNT Wirepas
	const [activeAnchorToUpdate, setActiveAnchorToUpdate] = useState(null) // active anchor to update
	const [showWebcamActiveUpdateModal, setShowWebcamActiveUpdateModal] = useState(false)
	const [urlsActiveWebcamToUpdate, setUrlsActiveWebcamToUpdate] = useState([])
	const [showAddPhotosActiveModal, setShowAddPhotosActiveModal] = useState(false) // show add photos active anchors modal
	const [imagesActiveAddPhotos, setImagesActiveAddPhotos] = useState([])
	const [urlsModal2Active, setUrlsModal2Active] = useState([])
	const [initialData, setInitialData] = useState([])
	const [selectedRows, setSelectedRows] = useState([])
	const [showDeleteSeveral, setShowDeleteSeveral] = useState(false)
	const [showApproveModal, setShowApproveModal] = useState(false)
	const [dragMarker, setDragMarker] = useState(false)
	const [showBigImageModal, setShowBigImageModal] = useState(false)
	const [selectedBigImage, setSelectedBigImage] = useState()
	const [showBigImageModalActive, setShowBigImageModalActive] = useState(false)
	const [selectedBigImageActive, setSelectedBigImageActive] = useState()
	const [selectedImageIndexActive, setSelectedImageIndexActive] = useState([])
	const [selectedImageIndexPosition, setSelectedImageIndexPosition] = useState([])
	// ↓ drawer options control state
	const [drawerControl, setDrawerControl] = useState()
	const [openDrawerBar, setOpenDrawerBar] = useState(false)
	const [drawerOptions, setDrawerOptions] = useState({
		openSearch: false
	})
	const [goTo, setGoTo] = useState()
	// ↓ drawer options control state
	const [drawerListControl, setDrawerListControl] = useState()
	const [drawerListOptions, setDrawerListOptions] = useState({
		openList: false
		//! maybe here i can have bool for filter
	})
	const [drawerListMarkers, setDrawerListMarkers] = useState([])

	// ━━━━━━━━ useEffects ━━━━━━━━\\

	useEffect(() => {
		if (!selectedCustomerId) {
			dispatch({
				type: "SNACKBAR_WARNING",
				payload: `Please Select Customer`
			})
			return
		}
	}, [selectedCustomerId])

	// ━━━━━━━━ fetch anchorsPositioning ━━━━━━━━
	useEffect(() => {
		if (!selectedSite) return

		dispatch(
			poiActions.fetchAnchorsPosition({
				siteId: selectedSite?.id
			})
		)
	}, [selectedSite])

	// ━━━━━━━━ Set fetched AnchorsData to state ━━━━━━━━
	useEffect(() => {
		anchorsPositionData && setAnchorsPositioning(anchorsPositionData)
	}, [anchorsPositionData])

	// ━━━━━━━━ Set fetched Anchors to update to state, (after click to edit Anchor) ━━━━━━━━
	useEffect(() => {
		anchorsPositionToUpdate && setPoiToUpdate(anchorsPositionToUpdate)
		anchorsPositionToUpdate && setOldAnchorDoc(anchorsPositionToUpdate)
	}, [anchorsPositionToUpdate])

	// ━━━━━━━━ Set fetched Active Anchors to update to state, (after click to edit Active anchor) ━━━━━━━━
	useEffect(() => {
		activeAnchorToUpdate && setOldAnchorDoc(activeAnchorToUpdate)
		activeAnchorToUpdate?.imagesUrls && setUrlsModal2Active(activeAnchorToUpdate?.imagesUrls)
	}, [activeAnchorToUpdate])

	// ━━━━━━━━ Map ━━━━━━━━
	useEffect(() => {
		const map = new mapboxgl.Map({
			container: mapcontainerref.current,
			style: "mapbox://styles/mapbox/streets-v11"
		})

		const showAnchorsMarkersBtnOnline = new MapControlsButtons({
			className: "mapbox-gl-show-anchors-online",
			title: "Show active Anchors",
			eventHandler: clickToShowAnchorsOnline
		})

		const showAnchorsMarkersBtnRemoved = new MapControlsButtons({
			className: "mapbox-gl-show-anchors-removed",
			title: "Show removed Anchors",
			eventHandler: clickToShowAnchorsRemoved
		})

		const showAnchorsMarkersBtnLost = new MapControlsButtons({
			className: "mapbox-gl-show-anchors-lost",
			title: "Show lost Anchors",
			eventHandler: clickToShowAnchorsLost
		})

		const showAnchorsMarkersBtnUid = new MapControlsButtons({
			className: "mapbox-gl-show-anchors-uid",
			title: "Show Anchors Uid",
			eventHandler: clickToShowAnchorsUid
		})

		const makeDraggableBtn = new MapControlsButtons({
			className: "mapbox-gl-drag-anchors",
			title: "Drag markers",
			eventHandler: clickToDragMarkers
		})

		map.on("load", function (e) {
			// Map controls
			map.addControl(
				new mapboxgl.NavigationControl({
					showCompass: false
				}),
				"top-left"
			)
			map.addControl(new MapDimensions(), "bottom-left")
			map.addControl(showAnchorsMarkersBtnOnline, "top-left")
			map.addControl(showAnchorsMarkersBtnRemoved, "top-left")
			map.addControl(showAnchorsMarkersBtnLost, "top-left")
			map.addControl(showAnchorsMarkersBtnUid, "top-left")
			map.addControl(makeDraggableBtn, "top-left")

			setMap(map)
		})

		// disable zoom when doubleClick map
		map.doubleClickZoom.disable()
	}, [])

	// ━━━━━━━━ Mapbox Custom Controls functions ━━━━━━━━\\
	const clickToDragMarkers = () => {
		setDragMarker(prevState => !prevState)
	}

	const clickToShowAnchorsOnline = () => {
		setShowAnchorsOnline(prevState => !prevState)
	}
	const clickToShowAnchorsRemoved = () => {
		setShowAnchorsRemoved(prevState => !prevState)
	}
	const clickToShowAnchorsLost = () => {
		setShowAnchorsLost(prevState => !prevState)
	}
	const clickToShowAnchorsUid = () => {
		setShowAnchorsUid(prevState => !prevState)
	}

	//━━━━━━━━ Change Floors Control Button ━━━━━━━━\\
	useEffect(() => {
		if (!map) return

		if (floorsControl) map.removeControl(floorsControl)

		const _floorsControl = new ChangeFloorControlNew({
			dispatch: dispatch,
			selectedSite: selectedSite,
			selectedFloorPlan: currentFloorPlan,
			floorPlans: floorPlanData,
			handleClick
		})
		map.addControl(_floorsControl, "bottom-left")
		setFloorsControl(_floorsControl)
	}, [floorPlanData, selectedSite, currentFloorPlan, map])

	// ━━━━━━━━ Select new floorPlan (control floors buttons)
	const handleClick = floorId => {
		if (!floorId) return

		const foundedFloorPlan = floorPlanData && floorPlanData.find(f => f.id === floorId)

		foundedFloorPlan && dispatch(actions.floorPlanSelected(foundedFloorPlan))
	}

	// ━━━━━━━━ Set fetched Active Anchors Data to state ━━━━━━━━
	useEffect(() => {
		if (!anchorsData || anchorsData.length === 0) return
		anchorsData && setActiveAnchors(anchorsData)
	}, [anchorsData])

	// ━━━━━━━━━━━━━ Drawer Anchors ━━━━━━━━━━━━━ \\
	useEffect(() => {
		if (!map) return

		if (drawerControl) map.removeControl(drawerControl)

		const filteredAnchors = anchorsPositioning?.filter(poi => poi.status !== "created")

		const _drawerControl = new DrawerAnchors({
			selectedSite,
			openDrawerBar,
			setOpenDrawerBar,
			drawerOptions,
			setDrawerOptions,
			map,
			dispatch,
			activeAnchors,
			positionAnchors: filteredAnchors,
			floorPlans: floorPlanData,
			floorPlan: currentFloorPlan,
			setGoTo,
			setShowAnchorsOnline
		})

		map.addControl(_drawerControl, "top-right")
		setDrawerControl(_drawerControl)
	}, [
		map,
		selectedSite,
		openDrawerBar,
		drawerOptions,
		anchorsPositioning,
		activeAnchors,
		floorPlanData,
		currentFloorPlan
	])

	// ━━━━━━━━━━━━━ Drawer List Anchors ━━━━━━━━━━━━━ \\
	useEffect(() => {
		if (!map) return

		if (drawerListControl) map.removeControl(drawerListControl)

		const div = document.getElementsByClassName("hide-search-drawer-bar")
		if (div && div.length > 0) {
			if (drawerListOptions.openList) {
				div[0].style.display = "none"
			} else {
				div[0].style.display = "block"

				// Remove all drawerListMarkers from the map when openList is false
				//!if i remove this i can close the drawer and continuing positioning the markers
				// drawerListMarkers.forEach(marker => marker.remove())
				// setDrawerListMarkers([])
			}
		}

		const _drawerListControl = new DrawerListAnchors({
			selectedSite,
			drawerListOptions, // → all drawer list options object
			map,
			anchorsPositionData: anchorsPositioning,
			drawerListMarkers,
			setDrawerListMarkers,
			handleDrawerListMarkerPositionChange,
			modelsInventory
		})

		map.addControl(_drawerListControl, "top-right")
		setDrawerListControl(_drawerListControl)
	}, [selectedSite, drawerListOptions, map, modelsInventory])

	// ━━━━━━━━━━━━━ Drawer List Anchors Handle Placed Marker Position Change ━━━━━━━━━━━━━ \\
	const handleDrawerListMarkerPositionChange = marker => {
		if (!currentFloorPlan || !selectedSite.id || !user) return

		const oldPoi = anchorsPositioning.find(poi => poi.uuid === marker.uuid)

		const updatedPoi = {
			...oldPoi,
			gpsData: new firebase.firestore.GeoPoint(marker.latitude, marker.longitude),
			markerOfPOIsId: oldPoi.id,
			floorPlanId: currentFloorPlan.id,
			status: marker.status
		}

		if (!oldPoi) return
		// dispatch(
		// 	poiActions.saveAnchorSingleMarkers({
		// 		siteId: selectedSite.id,
		// 		marker: updatedPoi,
		// 		oldAnchorDoc: oldPoi,
		// 		user: user,
		// 		network_address: selectedSite.netWirepas.toString(),
		// 		wirepasFloorPlanId: currentFloorPlan.wirepasFloorPlanId
		// 	})
		// )

		// console.log("drag from table")

		dispatch(
			poiActions.updateAnchor({
				oldAnchorDoc: oldPoi,
				network_address: selectedSite.netWirepas.toString() || null,
				wirepasFloorPlanId: currentFloorPlan.wirepasFloorPlanId || null,
				nodes: [updatedPoi.id],
				status: marker.status,
				siteId: selectedSite.id,
				nodeType: "anchor",
				latitude: marker.latitude || null,
				longitude: marker.longitude || null,
				floorPlanId: currentFloorPlan.id || null,
				callersUserId: user.id,
				callersTenantId: user.tenantId || null,
				callersEmail: user.email,
				databaseId: selectedCustomer.databaseId || null
			})
		)

		// Update POIs state
		let newPointsOfInterest = [...anchorsPositioning]
		const poiIndex = newPointsOfInterest.findIndex(e => e.id === updatedPoi.id)

		newPointsOfInterest[poiIndex] = {
			...newPointsOfInterest[poiIndex],
			floorPlanId: currentFloorPlan.id,
			gpsData: new firebase.firestore.GeoPoint(marker.latitude, marker.longitude),
			edit: true,
			status: marker.status
		}

		setAnchorsPositioning(newPointsOfInterest)

		const markerToRemove = drawerListMarkers.find(
			drawerMarker => drawerMarker.uuid === marker.uuid
		)
		if (markerToRemove) {
			markerToRemove.remove()
		}

		setCollectionChanged(true)
		return
	}

	//━━━━━━━━ GeoJson areas on map ━━━━━━━━\\
	useEffect(() => {
		//O "filter" é a variavel do floorPlan selecionado
		if (map && floorPlanData) {
			// Ã¢â€ â€œ O "filter" é a variavel com todas as zonas do floorPlan selecionado e estÃƒÂ¡ sempre c/ a data actualizada.
			const filter = floorPlanData.find(floor => floor.id === currentFloorPlan?.id)

			// Ã¢â€ â€œ map animation duration at start
			const animationOptions = {
				duration: 0,
				animate: false
			}

			// Ã¢â€ â€œ If exists is this floorPlan mapOrientation it displays orientation from db
			if (Boolean(filter?.mapOrientation) === true) {
				const lat = filter.mapOrientation.center.latitude
				const lng = filter.mapOrientation.center.longitude
				map.setZoom(filter.mapOrientation.zoom)
				map.setBearing(filter.mapOrientation.bearing)
				map.setPitch(filter.mapOrientation.pitch)
				map.setCenter([lng, lat])
				const newData = {
					...filter.mapOrientation,
					center: { lng: lng, lat: lat }
				}
			} else {
				// Ã¢â€ â€œ Onde é centrado o mapa por default
				filter?.coords?.topLeftCorner?.coords?.longitude &&
					map.fitBounds(
						[
							[
								filter?.coords?.topLeftCorner?.coords?.longitude,
								filter?.coords?.topLeftCorner?.coords?.latitude
							],
							[
								filter?.coords?.bottomRightCorner?.coords?.longitude,
								filter?.coords?.bottomRightCorner?.coords?.latitude
							]
						],
						{ duration: 0 }
					)
			}

			// Codigo para por a imagem no mapa (NÃƒÂ£o esqueÃƒÂ§er de remover a layer e a source antiga antes de por as novas)
			if (map) {
				let layer = map.getLayer("floorPlanLayer")
				if (typeof layer !== "undefined") {
					map.removeLayer("floorPlanLayer")
				}
				let source = map.getSource("floorPlanSource")
				if (typeof source !== "undefined") {
					map.removeSource("floorPlanSource")
				}
			}
			if (map && filter) {
				if (filter?.imageUrl) {
					// Add a data source containing GeoJSON data. (floorPlan/planta on background)
					map.addSource("floorPlanSource", {
						type: "image",
						url: filter?.imageUrl,
						coordinates: [
							[
								filter.coords.topLeftCorner?.coords?.longitude,
								filter.coords.topLeftCorner?.coords?.latitude
							],
							[
								filter.coords.topRightCorner?.coords?.longitude,
								filter.coords.topRightCorner?.coords?.latitude
							],
							[
								filter.coords.bottomRightCorner?.coords?.longitude,
								filter.coords.bottomRightCorner?.coords?.latitude
							],
							[
								filter.coords.bottomLeftCorner?.coords?.longitude,
								filter.coords.bottomLeftCorner?.coords?.latitude
							]
						]
					})
					map.addLayer(
						{
							id: "floorPlanLayer",
							source: "floorPlanSource",
							type: "raster",
							paint: {
								"raster-opacity": 0.8
							}
						},
						// "gl-draw-polygon-fill-inactive.cold"
						"state-label"
					)
				}

				let finalFeatures = []

				if (filter?.geoJson) {
					filter.geoJson.features.forEach((feature, index) => {
						let currentType = roomTypes?.find(type => type.id === feature.properties.type)
						let coordinates = []
						feature.geometry.coordinates.forEach(coords => {
							coordinates.push([coords.longitude, coords.latitude])
						})
						finalFeatures.push({
							type: filter.geoJson.features[index].type,
							properties: {
								...filter.geoJson.features[index].properties,
								color: currentType ? currentType.color : "#e9ecef",
								outline: currentType ? currentType.color : "#dee2e6",
								opacity: 0.4
							},
							geometry: {
								type: filter.geoJson.features[index].geometry.type,
								coordinates: [coordinates]
							}
						})
					})

					let finalLayer = {
						type: "FeatureCollection",
						features: finalFeatures
					}
					if (finalFeatures.length === 0) {
						dispatch({
							type: "SNACKBAR_WARNING",
							payload: `Note: The selected floor doesn't have Areas created.`
						})
					}

					addGeoJsonLayerToMap({
						map: map,
						geoJson: finalLayer
					})
				}
			}
		}
	}, [currentFloorPlan, floorPlanData, map])

	// ━━━━━━━━━━━ Anchors ━━━━━━━━━━━ \\
	useEffect(() => {
		if (!map || !selectedSite || !currentFloorPlan) return
		if (!activeAnchors || activeAnchors.length <= 0) return

		addNodesToMap({
			map,
			selectedSite,
			selectedCustomer,
			user,
			currentFloorPlan,
			nodesData: activeAnchors,
			markers: anchorsMarkers,
			setMarkers: setAnchorsMarkers,
			showOnline: showAnchorsOnline,
			showRemoved: showAnchorsRemoved,
			showLost: showAnchorsLost,
			showAnchorsUid: showAnchorsUid,
			setShowActiveModal,
			setActiveNodeToUpdate: setActiveAnchorToUpdate,
			setShowBigImageModalActive,
			setSelectedBigImageActive,
			setSelectedImageIndexActive,
			removePopupFunction,
			setActiveNodes: setActiveAnchors,
			defaultSrc: "/markers/anchor.svg",
			dragMarker,
			dragSvg,
			nodeType: "anchor",
			poiActions,
			firebase,
			dispatch
		})
	}, [
		map,
		selectedSite,
		currentFloorPlan,
		activeAnchors,
		showAnchorsOnline,
		showAnchorsRemoved,
		showAnchorsLost,
		showAnchorsUid,
		dragMarker
	])

	// Change color of the showAnchorsOnline marker on map when clicked
	useEffect(() => {
		if (!map) return

		const showAnchorsButton = document.getElementsByClassName("mapbox-gl-show-anchors-online")
		const showAnchorsRemovedButton = document.getElementsByClassName(
			"mapbox-gl-show-anchors-removed"
		)
		const showAnchorsLostButton = document.getElementsByClassName("mapbox-gl-show-anchors-lost")
		const showAnchorsUidButton = document.getElementsByClassName("mapbox-gl-show-anchors-uid")

		if (showAnchorsOnline) {
			if (showAnchorsButton && showAnchorsButton[0]) {
				showAnchorsButton.forEach(val => {
					val.style.backgroundColor = "#2649AA"
				})
			}
		} else {
			if (showAnchorsButton && showAnchorsButton[0]) {
				showAnchorsButton.forEach(val => {
					val.style.backgroundColor = "#132659"
				})
			}
		}
		if (showAnchorsRemoved) {
			if (showAnchorsRemovedButton && showAnchorsRemovedButton[0]) {
				showAnchorsRemovedButton.forEach(val => {
					val.style.backgroundColor = "#ff0000"
				})
			}
		} else {
			if (showAnchorsRemovedButton && showAnchorsRemovedButton[0]) {
				showAnchorsRemovedButton.forEach(val => {
					val.style.backgroundColor = "#b00000"
				})
			}
		}
		if (showAnchorsLost) {
			if (showAnchorsLostButton && showAnchorsLostButton[0]) {
				showAnchorsLostButton.forEach(val => {
					val.style.backgroundColor = "#A43912"
				})
			}
		} else {
			if (showAnchorsLostButton && showAnchorsLostButton[0]) {
				showAnchorsLostButton.forEach(val => {
					val.style.backgroundColor = "#5f2009"
				})
			}
		}

		const removeClass = showAnchorsUid
			? "mapbox-gl-show-anchors-uid-image-show-uuid"
			: "mapbox-gl-show-anchors-uid-image-dont-show-uuid"
		const addClass = showAnchorsUid
			? "mapbox-gl-show-anchors-uid-image-dont-show-uuid"
			: "mapbox-gl-show-anchors-uid-image-show-uuid"

		if (showAnchorsUid) {
			if (showAnchorsUidButton && showAnchorsUidButton[0]) {
				showAnchorsUidButton.forEach(val => {
					val.classList.remove(removeClass)
					val.classList.add(addClass)
				})
			}
		} else {
			if (showAnchorsUidButton && showAnchorsUidButton[0]) {
				showAnchorsUidButton.forEach(val => {
					val.classList.remove(removeClass)
					val.classList.add(addClass)
				})
			}
		}
	}, [map, showAnchorsOnline, showAnchorsRemoved, showAnchorsLost, showAnchorsUid])

	// ━━━━━━━━ Add Anchors Position Markers ━━━━━━━━
	useEffect(() => {
		markers &&
			markers.forEach(m => {
				m && m.remove()
			})

		setIdsToUnplace([])

		let allMarkers = []

		anchorsPositioning &&
			anchorsPositioning.forEach(poi => {
				if (
					map &&
					poi.floorPlanId === currentFloorPlan?.id &&
					!poi.approved &&
					poi.status !== "created" &&
					poi.status !== "assigned" &&
					poi.status !== "unassigned"
				) {
					let marker = document.createElement("div")

					// marker.id = poi.id
					marker.id = poi.uuid
					marker.style.cursor = !dragMarker ? "pointer" : "grab"
					marker.style.backgroundSize = "34px"
					marker.style.backgroundPosition = "center"
					marker.style.opacity = "1"

					// se o marker estiver no centro é considerado como novo, p o user saber q ainda n moveu o marker.
					let markerOnCenter = false
					const { lng, lat } = map.getCenter()

					if (poi?.gpsData?.latitude === lat || poi?.gpsData?.longitude === lng) {
						markerOnCenter = true
					}

					marker.className =
						poi.status === "placed"
							? "anchorMarkersDesignStudio_placed"
							: poi.new || markerOnCenter
							? "anchorMarkersDesignStudio_new"
							: "anchorMarkersDesignStudio"

					const uidDiv = showAnchorsUid && (
						<span
							style={{
								position: "absolute",
								color: "#4A4A68",
								top: "-35px",
								left: "50%",
								textAlign: "center",
								minWidth: "60px",
								transform: "translateX(-50%)",
								background: "#FFFFFF",
								padding: "4px",
								borderRadius: "2px",
								fontSize: "12px",
								whiteSpace: "nowrap",
								pointerEvents: "none"
							}}
						>
							{"UUID: " + poi.uuid}
							<div
								style={{
									content: "''",
									position: "absolute",
									bottom: "-6px",
									left: "50%",
									transform: "translateX(-50%)",
									width: "0",
									height: "0",
									borderLeft: "6px solid transparent",
									borderRight: "6px solid transparent",
									borderTop: "6px solid #FFFFFF"
								}}
							></div>
						</span>
					)

					const popupStickNew = !dragMarker ? (
						<PopupStickOnHoverManageInfrastructure
							component={
								<div
									style={{
										background: "#ECF1F4",
										boxShadow: "4px 4px 6px 2px rgba(0, 0, 0, 0.3)",
										borderRadius: "8px",
										fontFamily: "Poppins",
										fontStyle: "normal",
										color: "#4A4A68",
										paddingBottom: "2px",
										width: "276px",
										marginBottom: "-5px"
									}}
								>
									<Carousel
										indicators={false}
										controls={poi?.imagesUrls?.length > 1}
										interval={1500}
									>
										{poi?.imagesUrls?.length > 0 &&
											poi.imagesUrls.map((arrayUrl, i) => (
												<Carousel.Item key={i} interval={1000}>
													<img
														src={arrayUrl}
														alt="anchors position"
														style={{
															borderRadius: "5px 5px 0px 0px",
															height: "90px",
															width: "276px",
															objectFit: "cover",
															objectPosition: "center",
															cursor: "pointer"
														}}
														onClick={() => {
															setSelectedBigImage(poi)
															setSelectedImageIndexPosition(i)
															setShowBigImageModal(true)
															removePopupFunction("popup_stick_active_anchors")
														}}
													/>
												</Carousel.Item>
											))}
									</Carousel>
									<div className="d-flex justify-content-between align-items-center popup_title">
										<span>{poi.uuid}</span>
									</div>
									<Info anchorPosition={poi} />
								</div>
							}
							placement="top"
							onMouseEnter={() => {}}
							delay={500}
							className="popup_stick_active_anchors"
						>
							<div style={{ width: "34px", height: "34px" }}>
								{uidDiv}
								<img
									src="/markers/anchor.svg"
									alt="marker"
									className="iconImageAnchorsPosition"
								/>
							</div>
						</PopupStickOnHoverManageInfrastructure>
					) : (
						<PopupStickOnHoverManageInfrastructure
							component={<div></div>}
							placement="top"
							onMouseEnter={() => {}}
							delay={500}
							className="popup_stick_active_anchors"
						>
							<div style={{ width: "34px", height: "34px" }}>
								{uidDiv}
								<img
									src={dragSvg}
									alt="drag marker"
									className="iconImageAnchorsPositionDrag"
								/>
							</div>
						</PopupStickOnHoverManageInfrastructure>
					)

					// Render the marker with popup sticky
					ReactDOM.render(popupStickNew, marker)

					// Create the marker
					const markerWithImage =
						poi?.gpsData &&
						new mapboxgl.Marker(marker, {
							draggable: dragMarker
							// draggable: true
						}).setLngLat([poi?.gpsData?.longitude, poi?.gpsData?.latitude])

					// Add marker to map
					poi?.gpsData &&
						markerWithImage /*.setPopup(popup)*/
							.addTo(map)

					// Clicking on the marker closes the showEdit box
					poi?.gpsData &&
						markerWithImage.getElement().addEventListener("dblclick", () => {
							// console.log("double-clicked")
							setShowEdit(false)
							setShowCreatePOIs(false)
						})

					// When dragging the marker make sure that the popover doesn't get displayed
					poi?.gpsData &&
						markerWithImage.on("drag", () => {
							const popupElement = document.getElementById("popover")

							if (popupElement) {
								popupElement.style.display = "none"
							}
						})

					poi?.gpsData &&
						markerWithImage.on("dragend", val => {
							const lngLat = val.target.getLngLat()
							// console.log(lngLat, "lngLat")

							// Update POIs state
							let newPointsOfInterest = [...anchorsPositioning]
							const poiIndex = newPointsOfInterest.findIndex(e => e.id === poi.id)

							newPointsOfInterest[poiIndex] = {
								...newPointsOfInterest[poiIndex],
								floorPlanId: currentFloorPlan.id,
								gpsData: new firebase.firestore.GeoPoint(lngLat.lat, lngLat.lng),
								edit: true
							}

							setAnchorsPositioning(newPointsOfInterest)

							//  Save markers on dragend
							const newMarkerPosition = {
								...poi,
								gpsData: new firebase.firestore.GeoPoint(lngLat.lat, lngLat.lng),
								markerOfPOIsId: poi.id,
								floorPlanId: currentFloorPlan.id
							}
							// dispatch(
							// 	poiActions.saveAnchorSingleMarkers({
							// 		siteId: selectedSite.id,
							// 		marker: newMarkerPosition,
							// 		oldAnchorDoc: poi,
							// 		user: user,
							// 		network_address: selectedSite.netWirepas.toString(),
							// 		wirepasFloorPlanId: currentFloorPlan.wirepasFloorPlanId
							// 	})
							// )
							// console.log("re position")
							dispatch(
								poiActions.updateAnchor({
									oldAnchorDoc: poi,
									network_address: selectedSite.netWirepas.toString() || null,
									wirepasFloorPlanId: currentFloorPlan.wirepasFloorPlanId || null,
									nodes: [poi.id],
									status: poi.status,
									siteId: selectedSite.id,
									nodeType: "anchor",
									latitude: lngLat.lat || null,
									longitude: lngLat.lng || null,
									floorPlanId: currentFloorPlan.id || null,
									callersUserId: user.id,
									callersTenantId: user.tenantId || null,
									callersEmail: user.email,
									databaseId: selectedCustomer.databaseId || null
								})
							)
						})

					allMarkers.push(markerWithImage)
				}
			})

		setMarkers(allMarkers)

		// Change color of the dragMarker button on map when clicked
		const showAnchorsButton = document.getElementsByClassName("mapbox-gl-drag-anchors")

		if (dragMarker) {
			if (showAnchorsButton && showAnchorsButton[0]) {
				showAnchorsButton.forEach(el => {
					el.style.backgroundColor = "#6d6d6d"
				})
			}
		} else {
			if (showAnchorsButton && showAnchorsButton[0]) {
				showAnchorsButton.forEach(el => {
					el.style.backgroundColor = "#FFFFFF"
				})
			}
		}
	}, [map, currentFloorPlan, anchorsPositioning, selectedSite, user, dragMarker, showAnchorsUid])

	//━━━━━━━━ Functions ━━━━━━━━\\
	// maxNumber of photos added by user
	const maxNumber = 10

	// ━━━━━━━━ Open and close Modal 2
	const handleCloseModal2 = () => setShowModal2(false)
	const handleShowModal2 = () => {
		setShowModal2(true)
		poiToUpdate?.imagesUrls && setImagesModal2(poiToUpdate?.imagesUrls)
	}

	// ━━━━━━━━ Open and close Delete Modal 3
	const handleCloseDeleteModal = () => {
		setShowDeleteModal(false)
		setPoiIdToDelete(null)
	}
	const handleCloseDeleteModalAndDeletePOI = () => {
		// poiIdToDelete && deletePointOfInterest(poiIdToDelete?.id)
		unplaceAnchors(poiIdToDelete?.id)
		handleCloseDeleteModal()
	}

	// ━━━━━━━━ Saves poi.id to be deleted
	const handleShowDeleteModal = poi => {
		setShowDeleteModal(true)
		setPoiIdToDelete(poi)
	}

	const storageRef = storage.ref()

	// ━━━━━━━━ Set images to state if anchor to edit
	useEffect(() => {
		if (!poiToUpdate) return
		setImagesModal2(poiToUpdate?.imagesUrls)
		setUrlsModal2(poiToUpdate?.imagesUrls)
	}, [poiToUpdate])

	// ━━━━━━━━ Show uploaded images for that specific POI that we are editing
	const onChangeModal2 = (imageList, addUpdateIndex) => {
		const urlsDeleted =
			imagesModal2 && imagesModal2.filter(urls => !imageList.some(val => val === urls))
		// console.log("🚀  urlsDeleted:", urlsDeleted)

		urlsDeleted && setUrlsToDelete(prevState => [...prevState, ...urlsDeleted])
		setImagesModal2(imageList)

		// console.log("imageList onChangeModal2", imageList)
	}

	// ━━━━━━━━ Show uploaded images for that specific Active Anchor that we are editing
	const onChangeModal3 = (imageList, addUpdateIndex) => {
		// console.log("🚀  imageList:", imageList)

		const urlsDeleted =
			imagesActiveAddPhotos &&
			imagesActiveAddPhotos.filter(urls => !imageList.some(val => val === urls))

		// console.log("🚀  urlsDeleted:", urlsDeleted)

		urlsDeleted && setUrlsToDelete(prevState => [...prevState, ...urlsDeleted])

		setImagesActiveAddPhotos(imageList)
	}

	// ━━━━━━━━ Save Updated Active anchor ━━━━━━━━
	const handleSubmitUpdateActiveAnchor = async () => {
		// if uploaded new images on anchor edit
		const getImagesArray = async () => {
			if (Array.isArray(imagesActiveAddPhotos) && imagesActiveAddPhotos.length > 0) {
				const filteredArr = imagesActiveAddPhotos.filter(item => typeof item !== "string")

				if (filteredArr.length === 0) {
					return []
				}

				let res = await Promise.all(
					filteredArr.map(async image => await compressUpload3(image))
				)

				return res
			} else {
				return []
			}
		}

		const imagesArray = await getImagesArray()
		// console.log("🚀 NOVAAAA imagesArray:", imagesArray)

		// Compress, upload and return array of urlsWebcam
		const getPhotosTakenArray = async () => {
			if (urlsActiveWebcamToUpdate && urlsActiveWebcamToUpdate.length !== 0) {
				let res = await Promise.all(
					urlsActiveWebcamToUpdate.map(
						async image => await compressUploadWithoutDotFile(image)
					)
				)

				return res
			} else {
				return []
			}
		}

		const photoArray = await getPhotosTakenArray()

		// Filter urls that were deleted
		const urlsFilteredFromFirestore =
			(urlsModal2Active &&
				urlsModal2Active.length > 0 &&
				urlsModal2Active.filter(urls => !urlsToDelete.some(val => val === urls))) ||
			[]

		// Join newUrlsArray with urlsFilteredFromFirestore
		const newImageArray = [...urlsFilteredFromFirestore, ...imagesArray, ...photoArray]

		// Save in firestore
		// dispatch(
		// 	poiActions.updateActiveAnchorPosition({
		// 		data: {
		// 			...activeAnchorToUpdate,
		// 			id: activeAnchorToUpdate.uuid
		// 		},
		// 		siteId: selectedSite.id,
		// 		user: user,
		// 		newImagesUrlsArray: newImageArray ? newImageArray : [],
		// 		oldAnchorDoc: {
		// 			...oldAnchorDoc,
		// 			id: oldAnchorDoc.uuid.toString()
		// 		},
		// 		customerId: selectedCustomerId,
		// 		wirepasFloorPlanId: currentFloorPlan.wirepasFloorPlanId
		// 	})
		// )
		dispatch(
			poiActions.updateAnchor({
				oldAnchorDoc: [activeAnchorToUpdate?.uuid?.toString()],
				network_address: selectedSite.netWirepas.toString() || null,
				wirepasFloorPlanId: currentFloorPlan.wirepasFloorPlanId || null,
				nodes: [activeAnchorToUpdate?.uuid?.toString()],
				status: activeAnchorToUpdate.status,
				description: activeAnchorToUpdate.description || null,
				siteId: selectedSite.id,
				nodeType: "anchor",
				latitude: activeAnchorToUpdate.gpsData.latitude || null,
				longitude: activeAnchorToUpdate.gpsData.longitude || null,
				floorPlanId: currentFloorPlan.id || null,
				callersUserId: user.id,
				callersTenantId: user.tenantId || null,
				callersEmail: user.email,
				databaseId: selectedCustomer.databaseId || null,
				imagesUrls: newImageArray ? newImageArray : []
			})
		)
			.then(() => {
				// Change save/edit button
				const btnUploadFile = document.getElementById("buttonToProgress_editPoi")
				btnUploadFile &&
					finishButtonProgress(
						btnUploadFile,
						"button__progress_editPoi",
						"button__text_editPoi",
						100
					)
			})
			.then(() => {
				setTimeout(() => {
					setShowActiveModal(false)
				}, [500])
			})
			.then(() => {
				const newAnchorsPositioning = activeAnchors.map(anchor => {
					if (anchor.uuid === activeAnchorToUpdate?.uuid) {
						return {
							...anchor,
							id: activeAnchorToUpdate?.uuid?.toString(),
							status: activeAnchorToUpdate.status,
							description: activeAnchorToUpdate.description || null,
							imagesUrls: newImageArray
								? newImageArray
								: activeAnchorToUpdate.imagesUrls || []
						}
					}
					return anchor
				})

				setActiveAnchors(newAnchorsPositioning)

				setProgress(0)
				setImagesActiveAddPhotos([])
				setUrlsModal2Active([])
				setUrlsToDelete([])
				setUrlsActiveWebcamToUpdate([])
				handleGetAll()
				setActiveAnchorToUpdate(null)
				setOldAnchorDoc()
				// setCollectionChanged(true)
			})

		// Delete from storage the removed images on Anchor edit
		const imageNamesArray = []

		allImagesInStorage &&
			allImagesInStorage.map(image => {
				urlsToDelete &&
					urlsToDelete.filter(poiUrls => {
						if (image.url === poiUrls) {
							imageNamesArray.push(image.url)
						}
						return null
					})
			})

		imageNamesArray.length > 0 && handleDeleteFromStorage(imageNamesArray) // delete from storage
	}

	// ━━━━━━━━ Save Updated Anchor ━━━━━━━━
	const handleSubmitUpdatePOI = async () => {
		const getImagesArray = async () => {
			if (Array.isArray(imagesModal2) && imagesModal2.length > 0) {
				const filteredArr = imagesModal2.filter(item => typeof item !== "string")

				if (filteredArr.length === 0) {
					return []
				}

				let res = await Promise.all(
					filteredArr.map(async image => await compressUpload3(image))
				)

				return res
			} else {
				return []
			}
		}

		const imagesArray = await getImagesArray()

		// Compress, upload and return array of urlsWebcam
		const getPhotosTakenArray = async () => {
			if (urlsWebcamToUpdate && urlsWebcamToUpdate.length !== 0) {
				let res = await Promise.all(
					urlsWebcamToUpdate.map(async image => await compressUploadWithoutDotFile(image))
				)

				return res
			} else {
				return []
			}
		}

		const photoArray = await getPhotosTakenArray()

		// Filter urls that were deleted
		const urlsFilteredFromFirestore =
			(urlsModal2 &&
				urlsModal2.length > 0 &&
				urlsModal2.filter(urls => !urlsToDelete.some(val => val === urls))) ||
			[] // Ensure urlsFilteredFromFirestore is always an array

		// Join newUrlsArray with urlsFilteredFromFirestore
		const newImageArray = [...urlsFilteredFromFirestore, ...imagesArray, ...photoArray]

		// Save in firestore
		dispatch(
			poiActions.updateAnchor({
				oldAnchorDoc: [poiToUpdate?.uuid?.toString()],
				network_address: selectedSite.netWirepas.toString() || null,
				wirepasFloorPlanId: currentFloorPlan.wirepasFloorPlanId || null,
				nodes: [poiToUpdate?.uuid?.toString()],
				status: poiToUpdate.status,
				description: poiToUpdate.description || null,
				siteId: selectedSite.id,
				nodeType: "anchor",
				latitude: poiToUpdate.gpsData.latitude || null,
				longitude: poiToUpdate.gpsData.longitude || null,
				floorPlanId: currentFloorPlan.id || null,
				callersUserId: user.id,
				callersTenantId: user.tenantId || null,
				callersEmail: user.email,
				databaseId: selectedCustomer.databaseId || null,
				imagesUrls: newImageArray ? newImageArray : []
			})
		)
			.then(() => {
				// Change save/edit button
				const btnUploadFile = document.getElementById("buttonToProgress_editPoi")
				btnUploadFile &&
					finishButtonProgress(
						btnUploadFile,
						"button__progress_editPoi",
						"button__text_editPoi",
						100
					)
			})
			.then(() => {
				setTimeout(() => {
					setShowEdit(false)
				}, [500])
			})
			.then(() => {
				const newAnchorsPositioning = anchorsPositioning.map(anchor => {
					if (anchor.uuid === poiToUpdate?.uuid) {
						return {
							...anchor,
							id: poiToUpdate?.uuid?.toString(),
							status: poiToUpdate.status,
							description: poiToUpdate.description || null,
							imagesUrls: newImageArray ? newImageArray : poiToUpdate.imagesUrls || []
						}
					}
					return anchor
				})

				setAnchorsPositioning(newAnchorsPositioning)
				setCollectionChanged(true)
				setProgress(0)
				setImagesModal2([])
				setUrlsModal2([])
				setUrlsToDelete([])
				setUrlsWebcamToUpdate([])
				handleGetAll()
				setPoiToUpdate()
				setOldAnchorDoc()
			})

		// Delete from storage the removed images on Anchor edit
		const imageNamesArray = []

		allImagesInStorage &&
			allImagesInStorage.map(image => {
				urlsToDelete &&
					urlsToDelete.filter(poiUrls => {
						if (image.url === poiUrls) {
							imageNamesArray.push(image.url)
						}
						return null
					})
			})

		imageNamesArray.length > 0 && handleDeleteFromStorage(imageNamesArray) // delete from storage
	}

	// ━━━━━━━━ Save Approved Active anchors ━━━━━━━━
	const saveApprovedAnchors = placedData => {
		// console.log(" saveApprovedAnchors placedData ------>", placedData)

		setCollectionChanged(true)
		placedData &&
			placedData.forEach(val => {
				dispatch(anchorUnplaced({ id: val.id }))
			})
	}

	// ━━━━━━━━ Compress image on Anchors edit
	const compressUpload3 = async image => {
		// console.log("🚀 compressUpload3  image:", image)

		var options = {
			maxSizeMB: 1,
			maxWidthOrHeight: 1920,
			useWebWorker: true
		}

		const randomNumber = uuidv4()

		return new Promise(function (resolve, reject) {
			imageCompression(image.file, options)
				.then(function (compressedFile) {
					// console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`)

					// write upload logic
					const uploadTask = storageRef
						.child(
							`Sites/${selectedSite?.id}/anchorsImages/${compressedFile.name + randomNumber}`
						)
						.put(compressedFile)

					uploadTask.on(
						"state_changed",
						snapshot => {
							const progress = Math.round(
								(snapshot.bytesTransferred / snapshot.totalBytes) * 100
							)
						},
						error => {
							//console.log(error)
							reject()
						},
						() => {
							uploadTask.snapshot.ref.getDownloadURL().then(urlDownloaded => {
								// console.log("File available at", urlDownloaded)
								resolve(urlDownloaded)
							})
						}
					)
				})
				.catch(function (error) {
					console.log(error.message)
				})
		})
	}

	// ━━━━━━━━ Compress image on taken picture without image.file.
	const compressUploadWithoutDotFile = async image => {
		let imageUrl = image
		const randomNumber = uuidv4()

		return new Promise(function (resolve, reject) {
			imageCompression
				.getFilefromDataUrl(imageUrl, randomNumber)
				.then(function (compressedFile) {
					// console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`)

					// write upload logic
					const uploadTask = storageRef
						.child(`Sites/${selectedSite?.id}/anchorsImages/${compressedFile.name}`)
						.put(compressedFile)

					uploadTask.on(
						"state_changed",
						snapshot => {
							const progress = Math.round(
								(snapshot.bytesTransferred / snapshot.totalBytes) * 100
							)
						},
						error => {
							//console.log(error)
							reject()
						},
						() => {
							uploadTask.snapshot.ref.getDownloadURL().then(urlDownloaded => {
								// console.log("2 ------ File available at", urlDownloaded)
								resolve(urlDownloaded)
							})
						}
					)
				})
				.catch(function (error) {
					console.log(error.message)
				})
		})
	}

	// ━━━━━━━━ List/get all imagens from storage db ━━━━━━━━
	const handleGetAll = () => {
		const imagesRef = storageRef.child(`Sites/${selectedSite?.id}/anchorsImages`)
		imagesRef.listAll().then(data => {
			let allImagesInStorageArray = []
			data.items.forEach(itemRef => {
				itemRef.getDownloadURL().then(url => {
					allImagesInStorageArray.push({
						name: itemRef.name,
						url: url
					})
				})
			})
			// console.log("ðŸš€  allImagesInStorageArray:", allImagesInStorageArray)

			setAllImagesInStorage(allImagesInStorageArray)
			return allImagesInStorageArray
		})
	}

	// ━━━━━━━━ Get all images in storage
	useEffect(() => {
		handleGetAll()
	}, [])

	// ━━━━━━━━ Delete from storage handler
	const handleDeleteFromStorage = array => {
		array &&
			array.map(item => {
				// console.log(item, "item")

				const foundMatchOfImageUrl =
					allImagesInStorage && allImagesInStorage.find(image => image?.url === item)
				// console.log("━━━━━━━━  foundMatchOfImageUrl", foundMatchOfImageUrl)

				return storageRef
					.child(`Sites/${selectedSite.id}/anchorsImages/${foundMatchOfImageUrl.name}`)
					.delete()
					.then(() => {
						// console.log(`━━━━━━━━ deleted from storage - ${foundMatchOfImageUrl.name}`)
					})
			})
	}

	//   ━━━━━━ Fetch Anchors to update ━━━━━━
	const updatePointOfInterest = id => {
		if (anchorsPositioning) {
			dispatch(
				poiActions.fetchToUpdateAnchorAction({
					siteId: selectedSite.id,
					id: id
					// name: poiToUpdate.name
				})
			)
		}
	}

	const removePopupFunction = stringOfElement => {
		// remove popup when click
		const el = document.getElementsByClassName(stringOfElement)
		if (el) {
			el && el.forEach(val => (val.style.display = "none"))
		}
	}

	// ━━━━━━━━ Unplace Anchors
	const unplaceAnchors = async (id = null) => {
		let selectedData = []

		if (id) {
			selectedData.push(id)
		} else {
			selectedData = selectedRows
		}

		setIdsToUnplace(selectedData)

		// dispatch(
		// 	poiActions.unplaceAnchors({
		// 		siteId: selectedSite.id,
		// 		ids: selectedData,
		// 		user: user
		// 	})
		// )

		// console.log("unplaceAnchors")
		dispatch(
			poiActions.updateAnchor({
				nodes: selectedData,
				status: "assigned",
				siteId: selectedSite.id,
				nodeType: "anchor",
				oldAnchorDoc: selectedData,
				callersUserId: user.id,
				databaseId: selectedCustomer.databaseId || null
			})
		).then(() => {
			const updatedAnchorsPositioning =
				anchorsPositioning &&
				anchorsPositioning.map(poi => {
					if (selectedData.includes(poi.id)) {
						return {
							...poi,
							status: "assigned"
						}
					}
					return poi // Return the original object if it's not in selectedData
				})
			setAnchorsPositioning(updatedAnchorsPositioning)

			//updates drag and drop table
			setCollectionChanged(true)
			setShowDeleteSeveral(false)
		})
	}

	// ━━━━━━━━ placedOnSite Anchor on edit
	const handleChangeCheckbox = event => {
		setChecked(event.target.checked)
		// console.log("checked ->", event.target.checked)
		setPoiToUpdate({
			...poiToUpdate,
			// placedOnSite: event.target.checked
			status: event.target.checked ? "placed" : "planned"
		})
	}

	// ━━━━━━━━ Open and close Webcam Modal
	const handleCloseWebcamModal = () => {
		setShowWebcamModal(false)
	}

	// ━━━━━━━━ Open and close Webcam Update Modal
	const handleCloseWebcamUpdateModal = () => {
		setShowWebcamUpdateModal(false)
	}

	const handleCloseWebcamActiveUpdateModal = () => {
		setShowWebcamActiveUpdateModal(false)
	}

	// ━━━━━━━━ Show edit on hover condition
	let editCollapseCondition = false

	// ━━━━━━━━ Create Customm Toolbar for CardHeader with 'Positioning State' button
	const customCardHeaderToolbar = (
		<OverlayTrigger
			placement="bottom"
			overlay={<Tooltip id="positioning-tooltip">Open Anchors List</Tooltip>}
		>
			<Button
				text="Position Anchors"
				color="primary"
				variant="contained"
				onClick={() =>
					setDrawerListOptions(prevState => ({
						openList: !prevState.openList
					}))
				}
			/>
		</OverlayTrigger>
	)

	return (
		<Card>
			<CardHeader
				title={
					<>
						<div className="p-5">Anchors</div>
					</>
				}
				toolbar={customCardHeaderToolbar}
			/>
			<CardBody padding="0px">
				<div ref={mapcontainerref} style={{ height: "600px" }} id="mapbox-anchors-container" />
				<div className="mt-5">
					{/* <AnchorsTable
						initialData={initialData}
						setInitialData={setInitialData}
						anchorsPositioning={anchorsPositioning}
						selectedRows={selectedRows}
						setSelectedRows={setSelectedRows}
						unplaceAnchors={unplaceAnchors}
						showDeleteSeveral={showDeleteSeveral}
						setShowDeleteSeveral={setShowDeleteSeveral}
						showApproveModal={showApproveModal}
						setShowApproveModal={setShowApproveModal}
						saveApprovedAnchors={saveApprovedAnchors}
					/> */}
					<NodesTable
						initialData={initialData}
						setInitialData={setInitialData}
						nodesPositioning={anchorsPositioning}
						selectedRows={selectedRows}
						setSelectedRows={setSelectedRows}
						setShowDeleteSeveral={setShowDeleteModal}
						showApproveModal={showApproveModal}
						setShowApproveModal={setShowApproveModal}
						saveApprovedNodes={saveApprovedAnchors}
						columns={[
							{ field: "uuid", headerName: "UUID", width: 150 },
							{
								field: "status",
								headerName: "Anchor Status",
								width: 200
							},
							{
								field: "description",
								headerName: "Description",
								width: 200,

								renderCell: params => (
									<div
										style={{
											height: "100%",
											overflowX: "auto"
										}}
									>
										{params.value}
									</div>
								)
							},
							{ field: "floorPlanName", headerName: "Floorplan", width: 200 }
						]}
						unplaceIcon="/media/svg/icons/General/unplace-anchor.svg"
					/>
				</div>
			</CardBody>

			{poiToUpdate && (
				<EditNodeModal
					showEdit={showEdit}
					setShowEdit={setShowEdit}
					showModal2={showModal2}
					setImagesModal2={setImagesModal2}
					setUrlsToDelete={setUrlsToDelete}
					setOldNodeDoc={setOldAnchorDoc}
					poiToUpdate={poiToUpdate}
					setPoiToUpdate={setPoiToUpdate}
					setShowWebcamUpdateModal={setShowWebcamUpdateModal}
					urlsWebcamToUpdate={urlsWebcamToUpdate}
					imagesModal2={imagesModal2}
					onChangeModal2={onChangeModal2}
					maxNumber={maxNumber}
					handleShowModal2={handleShowModal2}
					handleCloseModal2={handleCloseModal2}
					setShowModal2={setShowModal2}
					urlsToDelete={urlsToDelete}
					checked={checked}
					handleChangeCheckbox={handleChangeCheckbox}
					setUrlsModal2={setUrlsModal2}
					startButtonProgress={startButtonProgress}
					handleSubmitUpdatePOI={handleSubmitUpdatePOI}
					activeNodes={activeAnchors}
					nodesPositioning={anchorsPositioning}
					title="Anchor"
				/>
			)}

			<EditActiveNodeModal
				showActiveModal={showActiveModal}
				setShowActiveModal={setShowActiveModal}
				activeNodeToUpdate={activeAnchorToUpdate}
				setActiveNodeToUpdate={setActiveAnchorToUpdate}
				setShowWebcamActiveUpdateModal={setShowWebcamActiveUpdateModal}
				showAddPhotosActiveModal={showAddPhotosActiveModal}
				setShowAddPhotosActiveModal={setShowAddPhotosActiveModal}
				maxNumber={maxNumber}
				imagesActiveAddPhotos={imagesActiveAddPhotos}
				setImagesActiveAddPhotos={setImagesActiveAddPhotos}
				onChangeModal3={onChangeModal3}
				urlsToDelete={urlsToDelete}
				setUrlsToDelete={setUrlsToDelete}
				setUrlsModal2Active={setUrlsModal2Active}
				startButtonProgress={startButtonProgress}
				handleSubmitUpdateActiveNode={handleSubmitUpdateActiveAnchor}
				setOldNodeDoc={setOldAnchorDoc}
				urlsActiveWebcamToUpdate={urlsActiveWebcamToUpdate}
				setUrlsActiveWebcamToUpdate={setUrlsActiveWebcamToUpdate}
				title="Anchor"
			/>

			<DeleteModal
				show={showDeleteModal}
				onHide={handleCloseDeleteModal}
				onDelete={handleCloseDeleteModalAndDeletePOI}
				poi={poiIdToDelete}
			/>

			{/* <WebcamModal
				show={showWebcamModal}
				onHide={handleCloseWebcamModal}
				urlsWebcam={urlsWebcam}
				setUrlsWebcam={setUrlsWebcam}
				title="Anchor"
			/> */}
			<WebcamModal
				show={showWebcamUpdateModal}
				onHide={handleCloseWebcamUpdateModal}
				urlsWebcam={urlsWebcamToUpdate}
				setUrlsWebcam={setUrlsWebcamToUpdate}
				title="Update Anchor"
			/>
			<WebcamModal
				show={showWebcamActiveUpdateModal}
				onHide={handleCloseWebcamActiveUpdateModal}
				urlsWebcam={urlsActiveWebcamToUpdate}
				setUrlsWebcam={setUrlsActiveWebcamToUpdate}
				title="Active Anchor"
			/>

			<BigImageModal
				show={showBigImageModal}
				handleClose={() => {
					setShowBigImageModal(false)
				}}
				node={selectedBigImage}
				selectedImageIndexPosition={selectedImageIndexPosition}
			/>

			<BigImageModal
				show={showBigImageModalActive}
				handleClose={() => {
					setShowBigImageModalActive(false)
				}}
				node={selectedBigImageActive}
				selectedImageIndexActive={selectedImageIndexActive}
			/>
		</Card>
	)
}

function Info(props) {
	const { anchorPosition } = props

	return (
		<div className="d-flex" style={{ padding: "0px 10px 8px 10px" }}>
			<div
				className={
					anchorPosition?.description && anchorPosition?.description !== ""
						? "flex-fill col-4 mr-2"
						: "flex-fill col-12"
				}
				style={{
					background: "#FFFFFF",
					borderRadius: "4px"
				}}
			>
				<div
					style={{
						margin: "3px",
						textAlign: "center",
						fontWeight: "300",
						lineHeight: "160%",
						fontSize: "10px",
						color: "#4A4A68"
					}}
				>
					Anchor
				</div>
				<hr className="m-0" />
				<div
					style={{
						margin: "3px",
						textAlign: "center",
						fontWeight: "600",
						lineHeight: "160%",
						fontSize: "10px",
						color: "#4A4A68"
					}}
				>
					{anchorPosition.status === "placed" ? "Placed" : "Planned"}
					{/* {anchorPosition.placedOnSite ? "Placed" : "Planned"} */}
				</div>
			</div>

			{anchorPosition?.description && anchorPosition?.description !== "" && (
				<div
					className="flex-fill col-8"
					style={{
						background: "#FFFFFF",
						borderRadius: "4px",
						overflowY: "auto", // Enable vertical scrolling
						maxHeight: "75px" // Set the maximum height to display 2 lines of text
					}}
				>
					<div
						style={{
							margin: "3px",
							textAlign: "center",
							fontWeight: "300",
							lineHeight: "160%",
							fontSize: "10px",
							color: "#4A4A68"
						}}
					>
						Description
					</div>
					<hr className="m-0" />
					<div
						style={{
							margin: "3px",
							textAlign: "center",
							fontWeight: "600",
							lineHeight: "160%",
							fontSize: "11px",
							color: "#4A4A68"
						}}
					>
						{anchorPosition.description}
					</div>
				</div>
			)}
		</div>
	)
}
