import React from "react"
import { OverlayTrigger, Tooltip, Carousel } from "react-bootstrap"
import { Button } from "../../../../../_partials/Button"
import { BiEditAlt } from "react-icons/bi"

export const RenderActiveAnchorPopup = ({
	val,
	setSelectedBigImageActive,
	setSelectedImageIndexActive,
	setShowBigImageModalActive,
	setShowActiveModal,
	setActiveNodeToUpdate,
	checkIn,
	lastSeen,
	removePopupFunction,
	TimeInfo,
	DescriptionInfo,
	nodeType = "anchor"
}) => {
	return (
		<div
			style={{
				background: "#ECF1F4",
				boxShadow: "4px 4px 6px 2px rgba(0, 0, 0, 0.3)",
				borderRadius: "8px",
				fontFamily: "Poppins",
				fontStyle: "normal",
				color: "#4A4A68",
				paddingBottom: "2px",
				width: "276px",
				marginBottom: "-5px"
			}}
		>
			<Carousel indicators={false} controls={val?.imagesUrls?.length > 1} interval={1500}>
				{val?.imagesUrls?.length > 0 &&
					val.imagesUrls.map((arrayUrl, i) => (
						<Carousel.Item key={i} interval={1000}>
							<img
								src={arrayUrl}
								alt="anchor"
								style={{
									borderRadius: "5px 5px 0px 0px",
									height: "200px",
									width: "276px",
									objectFit: "cover",
									objectPosition: "center",
									cursor: "pointer"
								}}
								onClick={e => {
									setSelectedBigImageActive(val)
									setSelectedImageIndexActive(i)
									setShowBigImageModalActive(true)
									removePopupFunction(
										nodeType === "sensor"
											? "popup_stick_active_sensors"
											: "popup_stick_active_anchors"
									) // remove popup onClick
								}}
							/>
						</Carousel.Item>
					))}{" "}
			</Carousel>
			<div className="d-flex justify-content-between align-items-center popup_title">
				<span>{val.uuid}</span>
				<div>
					<OverlayTrigger placement="top" overlay={<Tooltip>Edit</Tooltip>}>
						<Button
							className={
								nodeType === "sensor"
									? "sensor_active_popup_edit_btn"
									: "anchor_active_popup_edit_btn"
							}
							color="primaryLight"
							startIcon={<BiEditAlt />}
							onClick={() => {
								setShowActiveModal(true)
								setActiveNodeToUpdate(val) // updatePointOfInterest(poi.id)
								removePopupFunction(
									nodeType === "sensor"
										? "popup_stick_active_sensors"
										: "popup_stick_active_anchors"
								)
							}}
						/>
					</OverlayTrigger>
				</div>
			</div>
			<TimeInfo anchor={val} checkIn={checkIn} lastSeen={lastSeen} />
			{val?.description && val?.description !== "" && <DescriptionInfo anchor={val} />}
		</div>
	)
}

export default RenderActiveAnchorPopup
